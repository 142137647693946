import React, { useState, useEffect, useContext } from "react";
import { Table, Pagination } from "rsuite";
import "./index.css";
import { toast } from "react-toastify";
import { columnDataForMedPulse } from "../../api/Postaction";
import { GoSearch } from "react-icons/go";
import { IoMdClose } from "react-icons/io";
import { CommonLoader } from "../../common";
import { MyContext } from "../../context/ContextApi";

const MedPulse = () => {
  const { setDrugColumn, isMedPulseData } = useContext(MyContext);
  const { Column, HeaderCell, Cell } = Table;
  const [columnData, setColumnData] = useState([]);
  const [records, setRecords] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [expSearch, setExpSearch] = useState(false);
  const [loading, setLoading] = useState(true);
  const startIndex = limit * (page - 1);

  useEffect(() => {
    // fetchColumnData();
    setLoading(true);

    if (isMedPulseData && Object.keys(isMedPulseData).length > 0) {
      setColumnData(isMedPulseData.sent_df_data);
      setDrugColumn(isMedPulseData.senti_col);
      setLoading(false);
    }
  }, [isMedPulseData]);

  // const fetchColumnData = async () => {
  //   setLoading(true);
  //   try {
  //     const data = await columnDataForMedPulse();
  //     if (data.length === 0) {
  //       toast.error("Failed to retrieve columns");
  //     } else {
  //       setColumnData(data.sent_df_data);
  //       setDrugColumn(data.senti_col);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching column data:", error);
  //     toast.error("Failed to retrieve columns", error);
  //   } finally {
  //     setLoading(false); // Set loading to false after data fetching completes
  //   }
  // };

  const filterData = () => {
    if (searchQuery.trim() === "") {
      return columnData;
    }
    return columnData?.filter((item) => {
      return Object.values(item).some((val) =>
        val.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  };

  useEffect(() => {
    const filteredData = filterData();
    const start = limit * (page - 1);
    const end = start + limit;
    const slicedData = filteredData?.slice(start, end);
    setRecords(slicedData);
  }, [columnData, page, limit, searchQuery]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const toggleExpSearch = () => {
    setExpSearch((prevExpSearch) => !prevExpSearch);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
    setPage(1); // Reset page when limit changes
  };

  return (
    <>
      <div className="contentScroll">
        <div className="container">
          <div className="d-flex justify-content-center my-5">
            <div className="col-9">
              <div className="row">
                <div className="col-12">
                  <h5 className="d-flex mt-3 mb-2">Med Pulse</h5>
                  <div className="row">
                    <label className="midLingoLabel">
                      Med Pulse unveiling the emotional landscape of
                      pharmaceutical data through sentiment analysis
                    </label>
                    <div className="mt-3 col-12">
                    
                      {loading ? (
                        <CommonLoader />
                      ) : (
                        records?.length > 0 && (
                          <>
                            <div className="mb-1">
                              <div className="searchContainer">
                                <input
                                  type="search"
                                  className={expSearch && "exp-search-show"}
                                  placeholder="Search..."
                                  value={searchQuery}
                                  onChange={handleSearchChange}
                                />
                                <button
                                  type="button"
                                  className="btnSearch"
                                  onClick={toggleExpSearch}
                                >
                                  {expSearch ? <IoMdClose /> : <GoSearch />}
                                </button>
                              </div>
                            </div>
                            <Table
                              className="custom-table"
                              virtualized
                              wordWrap="break-word"
                              hover={false}
                              height={400}
                              data={records}
                              style={{
                                scrollbarWidth: "0",
                                maxHeight: "50vh",
                                border: "1px solid #4C85F7",
                              }}
                              align="center"
                            >
                              {[...Object.keys(records[0])].map(
                                (key, index) => (
                                  <Column
                                    flexGrow={
                                      key === "S.No"
                                        ? 1
                                        : key.toLowerCase().includes("comments")
                                        ? 3
                                        : 2
                                    }
                                    verticalAlign="middle"
                                    key={key}
                                  >
                                    <HeaderCell>{key}</HeaderCell>
                                    <Cell dataKey={key}>
                                      {(rowData, rowIndex) => {
                                        // if (key === "S.No") {
                                        //   return startIndex + rowIndex + 1; // Render serial number
                                        // }
                                        return rowData[key];
                                      }}
                                    </Cell>
                                  </Column>
                                )
                              )}
                            </Table>
                            <div style={{ paddingTop: 20 }}>
                              <Pagination
                                prev
                                next
                                first
                                last
                                ellipsis
                                boundaryLinks
                                maxButtons={5}
                                size="xs"
                                layout={[
                                  "total",
                                  "-",
                                  "limit",
                                  "|",
                                  "pager",
                                  "skip",
                                ]}
                                total={columnData.length}
                                limitOptions={[10, 30, 50]}
                                limit={limit}
                                activePage={page}
                                onChangePage={handlePageChange}
                                onChangeLimit={handleLimitChange}
                              />
                            </div>
                          </>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MedPulse;
