import React, { useEffect, useState, useContext } from "react";
import { Table } from "rsuite";
import { MyContext } from "../../context/ContextApi";
import { useLocation } from "react-router";
import CommonBtn from "../commonBtn";
import { Modal } from "react-bootstrap";
import {
  getCommeFaq,
  getDashFaqApi,
  getDocSonarFaq,
  getPubMedFaq,
  getDataVistaFaq,
} from "../../api/GetActions";

function FaqComponent({
  showModal,
  title = "FAQs",
  onCancelTitle = "Close",
  onCancel,
}) {
  const [faqData, setFaqData] = useState([]);
  const { setFaqInputValue, currentFaqVal, currentFaqValPubMed } =
    useContext(MyContext);
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    if (
      (currentFaqVal?.currentColl && currentFaqVal?.currentPath) ||
      currentFaqValPubMed
    ) {
      const faqGetData = async () => {
        let dataLens = currentPath.startsWith("/newquery");
        let docSonnar = currentPath.startsWith("/documentquery");
        let comDataDet = currentPath.startsWith("/comdataDetective");
        let pubMed = currentPath.startsWith("/pubmedquery");
        let dataVista = currentPath.startsWith("/textchartquery");

        if (dataLens) {
          let getDashFaqStatus = await getDashFaqApi(currentFaqVal);
          setFaqData(getDashFaqStatus?.Questions_list);
        }
        if (docSonnar) {
          let getDashFaqStatus = await getDocSonarFaq(currentFaqVal);
          setFaqData(getDashFaqStatus?.Questions_list);
        }
        if (comDataDet) {
          let getDashFaqStatus = await getCommeFaq(currentFaqVal);
          setFaqData(getDashFaqStatus?.Questions_list);
        }
        if (pubMed) {
          let getDashFaqStatus = await getPubMedFaq(currentFaqValPubMed);
          setFaqData(getDashFaqStatus?.Questions_list);
        }
        if (dataVista) {
          let getDashFaqStatus = await getDataVistaFaq(currentFaqVal);
          setFaqData(getDashFaqStatus?.Questions_list);
        }
      };
      faqGetData();
    }
  }, [currentFaqVal, currentFaqValPubMed, currentPath]);

  const handleRowClick = (rowData) => {
    setFaqInputValue("");
    setTimeout(() => {
      setFaqInputValue(rowData?.question);
      onCancel();
    }, 0);
  };

  return (
    <Modal
      show={showModal}
      size={"lg"}
      centered
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal__list-content">
        <Table
          autoHeight
          data={faqData?.map((question, index) => ({
            id: index + 1,
            question,
          }))}
        >
          <Table.Column width={50} align="center" fixed>
            <Table.HeaderCell>#</Table.HeaderCell>
            <Table.Cell dataKey="id" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Questions</Table.HeaderCell>
            {/* <Table.Cell dataKey="question" /> */}
            <Table.Cell className="iconHover">
              {(rowData) => (
                <span onClick={() => handleRowClick(rowData)}>
                  {rowData.question}
                </span>
              )}
            </Table.Cell>
          </Table.Column>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <CommonBtn title={onCancelTitle} onClick={onCancel} className="mr-2" />
      </Modal.Footer>
    </Modal>
  );
}

export default FaqComponent;
