import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useController } from "react-hook-form";
import { MyContext } from "../../context/ContextApi";
import {
  audioGenerate,
  feedBack,
  sendEmail,
  sendDetailInWhatsApp,
  tableauChatHistory,
  tableau_dashboard_summary,
  tableauAudioHistory,
  searchGetDataJson,
  tableauDashboardPrompt,
  tableauChatSpeech,
} from "../../api/Postaction";

import { BsShareFill } from "react-icons/bs";
import { dashboardDetails } from "../../validation";
import {
  ChatBot,
  CommonArrowCircle,
  CommonBtn,
  CommonFeedback,
  CommonGenerateBtn,
  CommonLoader,
  CommonPromChat,
  CommonSendMail,
  CommonShareWhatsApp,
  LabelWithInput,
  LabelWithSelect,
  SearchInputArrowBtn,
  ThreeDotSpinner,
} from "../../common";
import { commonStr } from "../../utils/constants/commonStrings";
import { removeBulletPoints, showToast } from "../../utils/helpers/helpers";
import {
  getWorkBookApi,
  tableauLstProject,
  tableau_list_workbook,
} from "../../api/GetActions";

const ExicistingDashboard = () => {
  const { setSessionUpdate, dashboardname, filterContent } =
    useContext(MyContext);
  const location = useLocation();
  const [expand, setExpand] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dashboardOptions, setDashboardOptions] = useState([]);
  const [regionmessage, setRegionMessage] = useState("Loading...");
  const [dashboardmessage, setDashboardMessage] = useState("Loading...");
  const [region, setRegion] = useState([]);
  const [dashboardid, setDashboardid] = useState([]);
  const [loading1, setLoading1] = useState(false);
  const [regionOptions, setRegionOptions] = useState([]);
  const [shareModal, setShareModal] = useState(false);
  const [numberModal, setNumberModal] = useState(false);
  const [url, setUrl] = useState("");
  const [shareDetails, setShareDetails] = useState();
  const [sendNumber, setSendNumber] = useState();
  const [inputValue, setInputValue] = useState("");
  const [data, setData] = useState([]);
  const [result, setResult] = useState("");
  const [accountId, setAccountId] = useState("919490798061");
  const [audio, setAudio] = useState([]);
  const [test, setTest] = useState(false);
  const [RejectModal, setRejectModal] = useState(false);
  const [RejectAnswer, setRejectAnswer] = useState();
  const [audioData, setAudioData] = useState(null);
  const [feedbackInput, setFeedbackInput] = useState("");
  const [currentIdx, setCurrentIdx] = useState("");
  const [summarizeAudio, setSummarizeAudio] = useState("");
  const [isSummarizeClick, setIsSummarizeClick] = useState(false);
  const [isSetJsonData, setIsSetJsonData] = useState({});
  const [isBtnDisable, setIsBtnDisable] = useState(false);
  const [isListKpi, setIsListKpi] = useState([]);

  const [formData, setFormData] = useState({
    notes: "",
    emails: "",
  });

  const [formNumberData, setFormNumberData] = useState({
    number: "",
    text: "",
  });

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "180px", // Set the desired height here
      overflow: "scroll",
    }),
    // Add more styles for other components if needed
  };
  const customStyles1 = {
    control: (provided) => ({
      ...provided,
      width: "220px", // Set the desired height here
      overflow: "scroll",
    }),
    // Add more styles for other components if needed
  };

  let currentPath = location.pathname.substring(1);
  let activeSession = currentPath.split("/")[1];
  if (activeSession?.includes("%20")) {
    activeSession = activeSession.replace(/%20/g, " ");
  }
  let email = sessionStorage.getItem("email");

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    resolver: zodResolver(dashboardDetails),
    mode: "all",
  });

  const DashboardId = useController({
    name: "DashboardID",
    control,
  });
  const Region = useController({
    name: "Region",
    control,
  });
  const changeWorkBook = (option) => {
    DashboardId.field.onChange(option.value);
    if (option && option.value) {
      handleGetJsonData(option.value);
      handleGetListWorkBook(option.value);
    }
    setDashboardid(option);
    setSessionUpdate((current) => !current);
    dashboardname.name = option.label;
  };

  useEffect(() => {
    fetchDasboard(dashboardname.name);
    handleGetListWorkBook(dashboardname.name);
  }, []);

  const handleGetListWorkBook = async (data) => {
    setIsListKpi([]);
    let getKpiData = await getWorkBookApi(data);

    if (getKpiData && getKpiData?.kpi?.length > 0) {
      const isEmptyStringPresent =
        getKpiData.kpi &&
        getKpiData.kpi.length === 1 &&
        getKpiData.kpi.some((item) => item === "");
      if (isEmptyStringPresent) {
        setIsListKpi([]);
      } else {
        setIsListKpi(getKpiData?.kpi);
      }
    }
  };

  const handleGetJsonData = async (getValue) => {
    setIsBtnDisable(true);
    let getAcccountId = getValues("AccountID");

    let body = {
      account_id: getAcccountId,
      project: region.value,
      workbook: getValue,
    };
    let getJsonData = await searchGetDataJson(body);

    if (getJsonData) {
      setIsSetJsonData(getJsonData);
      setIsBtnDisable(false);
    }
    setIsBtnDisable(false);
  };

  const changeProject = (option) => {
    if (option && option.value) {
      fetchDasboard(option.value);
    }
    Region.field.onChange(option.value);
    dashboardname.region = option.label;
    setRegion(option);
    setValue("DashboardID", null);
    setDashboardid("");
    setDashboardOptions([]);
  };

  const fetchRegion = useCallback(async () => {
    let getAcccountId = getValues("AccountID");
    try {
      let res = await tableauLstProject(getAcccountId);
      if (res && res?.length > 0) {
        let options = res.map((e) => ({
          value: e,
          label: e,
        }));
        setRegionOptions(options);
      }
    } catch (err) {
      setRegionMessage("No Data");
    }
  }, [getValues, setRegionOptions, setRegionMessage]);

  useEffect(() => {
    fetchRegion();
  }, [fetchRegion]);
  const fetchDasboard = async (getValue) => {
    try {
      let res = await tableau_list_workbook(getValue);
      let a1 = res;
      let options = a1.map((e) => ({
        value: e,
        label: e,
      }));
      setDashboardOptions(options);
    } catch (err) {
      setDashboardMessage("No Data");
    }
  };
  useEffect(() => {
    if (shareDetails !== undefined) {
      setFormData({
        notes: "",
        emails: "",
      });
      setShareModal(true);
    }
  }, [shareDetails]);

  useEffect(() => {
    if (sendNumber !== undefined) {
      setFormNumberData({
        number: "",
        text: "",
      });
      setNumberModal(true);
    }
  }, [sendNumber]);

  useEffect(() => {
    setRegion({ label: dashboardname?.region, value: dashboardname?.region });
    setDashboardid({
      label: dashboardname?.name,
      value: dashboardname?.name,
    });
  }, [dashboardname, activeSession]);

  useEffect(() => {
    if (activeSession) {
      chatHistory();
    }
  }, [activeSession]);

  const handleSubmit1 = async () => {
    setTest(false);
    setLoading(true);
    setResult("");
    setSummarizeAudio("");
    let body = {
      data_json: isSetJsonData,
      kpi: isListKpi,
    };
    let a1 = await tableau_dashboard_summary(body);
    setResult(a1);
    let audioText = removeBulletPoints(a1);
    let b = await audioGenerate(audioText);
    setSummarizeAudio(b);
    let value = {
      email_id: email,
      session_id: activeSession,
      s3_url: b,
      workbook_name: dashboardid.value,
    };
    await tableauAudioHistory(value);
    setLoading(false);
    setTest(true);
    if (data.length === 0) {
      setSessionUpdate((current) => !current);
    }
  };
  const clearInput = () => {
    setLoading(false);
    setTest(false);
    setUrl("");
    setTest(false);
    setResult("");
    setInputValue("");
    setValue("DashboardID", null);
    setValue("Region", null);
    setDashboardid(null);
    setRegion(null);
  };
  const chatHistory = async () => {
    try {
      setData([]);
      setLoading1(true);
      let body = {
        email_id: email,
        session_id: activeSession,
        workbook_name: dashboardname.name,
        project_name: dashboardname.region,
        account_id: accountId,
      };
      let chat = await tableauChatHistory(body);

      setLoading1(false);
      let a = chat[0];
      let b = a.map((e, index) => ({
        id: index + 1,
        user: e.user,
        AI: e.AI,
        thumbsUp: e.Feedback === "thumbs-up" ? true : false,
        thumbsDown: e.Feedback === "thumbs-down" ? true : false,
      }));
      let audioList = a.map((e) => ({
        audio: e.AI_Audio,
        Message_Id: e.Message_Id,
      }));
      setData(b);
      setAudio(audioList);
      // }
    } catch (err) {
      console.log("Error in getting Accessable Table", err);
    }
  };

  const share = async (e) => {
    let checkFilePath = isSummarizeClick
      ? shareDetails
      : audio[currentIdx].audio;
    let checkDesc = isSummarizeClick ? result : shareDetails.AI;
    setShareModal(false);
    e.preventDefault();
    let body = {
      Note: formData.notes,
      recipients: [formData.emails],
      insight: checkDesc,
      file_path: checkFilePath,
      sender: email,
    };
    await sendEmail(body);
    setShareDetails();
  };

  const shareToWhatsApp = async (e) => {
    e.preventDefault();
    let checkDesc = isSummarizeClick
      ? result
      : sendNumber.user + "\n" + sendNumber.AI;
    setNumberModal(false);

    let body = {
      recipient_id: formNumberData.number,
      text: checkDesc,
    };
    await sendDetailInWhatsApp(body);
    setSendNumber();
  };

  const inputChange = () => {
    if (region.length !== 0 || dashboardid.length !== 0) {
      setRegion([]);
      setDashboardid([]);
      setValue("DashboardID", null);
      setValue("Region", null);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      generatePrompt();
    }
  };
  const generatePrompt = async () => {
    if (
      dashboardid === null &&
      Object.getOwnPropertyNames(isSetJsonData).length === 0
    ) {
      toast.error("Please Submit the Dashboard Details");
    } else {
      setGenerating(true);
      setInputValue("");
      let body = {
        // email_id: email,
        // dashboard_name: dashboardname.region,
        // session_id: activeSession,
        // region_name: dashboardname.region,
        // account_id: accountId,
        // user_query: inputValue,

        user_query: inputValue,
        data_json: isSetJsonData,
        email_id: email,
        workbook_name: dashboardid.value,
        dash_session_id: activeSession,
      };

      let a = await tableauDashboardPrompt(body);
      let b;
      if (a !== undefined) {
        setGenerating(false);
        setData((prev) => [
          ...prev,
          {
            user: inputValue,
            AI: a,
            thumbsUp: false,
            thumbsDown: false,
          },
        ]);
        let responseForAudio = removeBulletPoints(a);
        b = await audioGenerate(responseForAudio);

        let value = {
          email_id: email,
          session_id: activeSession,
          s3_url: b,
          workbook_name: dashboardid.value,
        };
        let id = await tableauAudioHistory(value);
        setAudio((prev) => [
          ...prev,
          {
            audio: b,
            Message_Id: id,
          },
        ]);
      }

      setGenerating(false);
    }
  };
  const handleThumbsUpClick = async (index) => {
    setRejectAnswer();
    const newThumbsStates = [...data];
    newThumbsStates[index].thumbsUp = !newThumbsStates[index].thumbsUp;
    newThumbsStates[index].thumbsDown = false; // Reset thumbs down
    setData(newThumbsStates);
    let a = data[index];
    let body = {
      message_id: audio[index].Message_Id,
      question: a.user,
      response: a.AI,
      feedback: "thumbs-up",
      email_id: email,
      session_id: activeSession,
    };

    let feedbackStatus = await feedBack(body);
    if (feedbackStatus?.status === 200) {
      showToast(feedbackStatus?.data?.message, 1);
    }
  };

  const handleThumbsDownClick = (e, index) => {
    let msgId = audio[index].Message_Id;
    let mergeData = {
      ...e,
      Message_Id: msgId,
    };
    setRejectModal(true);
    setRejectAnswer(mergeData);
    setFeedbackInput("");
    const newThumbsStates = [...data];
    newThumbsStates[index].thumbsDown = !newThumbsStates[index].thumbsDown;
    newThumbsStates[index].thumbsUp = false; // Reset thumbs up
    setData(newThumbsStates);
  };
  const closeRejectModal = () => {
    let a = data.filter((e) => e.user === RejectAnswer.user)[0];
    const indexOfObjectToUpdate = data.findIndex(
      (item) => item.user === a.user
    );
    if (indexOfObjectToUpdate !== -1) {
      data[indexOfObjectToUpdate].thumbsUp = false;
      data[indexOfObjectToUpdate].thumbsDown = false;
    }
    setRejectAnswer();
    setRejectModal(false);
  };
  const submitRejectModal = async (e) => {
    e.preventDefault();
    let body = {
      message_id: RejectAnswer.Message_Id,
      question: RejectAnswer.user,
      response: RejectAnswer.AI,
      feedback: "thumbs-down",
      comment: feedbackInput,
    };
    setRejectAnswer();
    setRejectModal(false);
    let feedbackStatus = await feedBack(body);
    if (feedbackStatus?.status === 200) {
      showToast(feedbackStatus?.data?.message, 1);
      setFeedbackInput("");
    }
  };
  //handleRecordData
  const handleRecordingData = (data) => {
    if (data) {
      setAudioData(data);
    }
  };

  const handleToStopAudio = async () => {
    if (
      dashboardid === null &&
      Object.getOwnPropertyNames(isSetJsonData).length === 0
    ) {
      toast.error("Please Submit the Dashboard Details");
    } else {
      if (audioData) {
        setAudioData("");
        setGenerating(true);

        try {
          let convertedData = await tableauChatSpeech(audioData);

          let body = {
            user_query: convertedData,
            data_json: isSetJsonData,
            email_id: email,
            workbook_name: dashboardid.value,
            dash_session_id: activeSession,
          };

          let audioRes = await tableauDashboardPrompt(body);
          let responseForAudio = removeBulletPoints(audioRes);
          let sectionData;

          if (responseForAudio !== undefined) {
            setGenerating(false);

            setData((prev) => [
              ...prev,
              {
                user: convertedData,
                AI: audioRes,
                thumbsUp: false,
                thumbsDown: false,
              },
            ]);

            sectionData = await audioGenerate(responseForAudio);
          }

          if (sectionData !== undefined) {
            let value = {
              email_id: email,
              session_id: activeSession,
              s3_url: sectionData,
              workbook_name: dashboardid.value,
            };
            let id = await tableauAudioHistory(value);

            setAudio((prev) => [
              ...prev,
              {
                audio: sectionData,
                Message_Id: id,
              },
            ]);
          }
        } catch (err) {
          console.log("error on generating prompt", err);
        }
      }
    }
  };

  useEffect(() => {
    if (audioData !== null) {
      handleToStopAudio();
    }
  }, [audioData]);

  //handleInputChange
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const closeShareModal = () => {
    setShareModal(false);
    setShareDetails();
  };

  const closePhoneNumberModal = () => {
    setNumberModal(false);
    setSendNumber();
  };

  const handleInputNumberChange = (e) => {
    const { name, value } = e.target;
    setFormNumberData({
      ...formNumberData,
      [name]: value,
    });
  };
  //handleToChangeTextArea
  const handleChangeTextArea = (e) => {
    setFeedbackInput(e.target.value);
  };

  const handleMergeEvaluageData = (evaluateData, id) => {
    let duplicateData = [...data];
    let mergeData = duplicateData.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          evaluate: evaluateData,
        };
      }
      return item;
    });
    setData(mergeData);
  };

  const sentences = result?.split("\n");

  return (
    <div className="dashboardmainContent">
      <div className="formLayout">
        {!expand && (
          <CommonArrowCircle
            className="headingdashboard iconHover"
            expand={!expand}
            onClick={() => setExpand(true)}
          />
        )}
        <form
          onSubmit={handleSubmit(handleSubmit1)}
          className="formDetails collapse"
          id="collapseExample"
        >
          <div className="d-flex align-items-center justify-content-around">
            <LabelWithInput
              labelClass="col-form-label"
              id="staticEmail"
              labelTitle="Account"
              value={accountId ? accountId : ""}
              errors={errors}
              onInput={inputChange}
              register={register}
              accountId="AccountID"
              inputClass="form-control"
            />

            <LabelWithSelect
              labelTitle="Project"
              placeholder="Select Project"
              value={region}
              options={regionOptions}
              onChange={changeProject}
              onFocus={fetchRegion}
              styles={customStyles}
              noOptionsMessage={() => regionmessage}
              autoFocus
              errors={errors && errors?.Region?.message}
            />

            <LabelWithSelect
              labelTitle="Work Book"
              placeholder="Work Book"
              value={dashboardid}
              options={dashboardOptions}
              styles={customStyles1}
              onChange={changeWorkBook}
              onFocus={() => fetchDasboard(region.value)}
              noOptionsMessage={() => dashboardmessage}
              errors={errors && errors?.DashboardID?.message}
            />
          </div>

          <div className="dashboardSummarize mt-3">
            <CommonBtn
              className={`summarize summarizeBtn ${
                isBtnDisable ? "summarizeDisable" : "iconHover"
              }  `}
              title={commonStr.summarize}
              type="submit"
              disabled={isBtnDisable}
            />
            <CommonBtn
              className="clear ml-3"
              title={commonStr.clear}
              type="button"
              onClick={clearInput}
            />
          </div>
          <div>
            {loading && <CommonLoader customLoaderClass="mt-4" />}
            {test && (
              <div className="result  mt-3">
                <div className="prompt">
                  <div className="answers">
                    <div className="ml-1 robot">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="29.182"
                        height="29.179"
                        viewBox="0 0 33.182 33.179"
                      >
                        <g id="bot" transform="translate(0 -0.019)">
                          <g id="Page-1_16_" transform="translate(0 0.019)">
                            <g
                              id="_x30_17---Chat-Bot"
                              transform="translate(0 0)"
                            >
                              <path
                                id="Shape_96_"
                                d="M232.268,284.214a1.716,1.716,0,0,0,1.716-1.716h-3.432A1.716,1.716,0,0,0,232.268,284.214Z"
                                transform="translate(-215.676 -264.192)"
                                fill="#4c85f7"
                              />
                              <circle
                                id="Oval_29_"
                                cx="0.572"
                                cy="0.572"
                                r="0.572"
                                transform="translate(21.741 14.873)"
                                fill="#4c85f7"
                              />
                              <path
                                id="Shape_95_"
                                d="M128.378,167.739h-6.865a5.721,5.721,0,1,0,0,11.441h6.865a5.721,5.721,0,1,0,0-11.441Zm-9.153,6.293a1.716,1.716,0,1,1,1.716-1.716A1.716,1.716,0,0,1,119.225,174.032Zm5.721,4a2.86,2.86,0,0,1-2.86-2.86,1.148,1.148,0,0,1,1.144-1.144h3.432a1.148,1.148,0,0,1,1.144,1.144A2.86,2.86,0,0,1,124.946,178.036Zm5.721-4a1.716,1.716,0,1,1,1.716-1.716A1.716,1.716,0,0,1,130.667,174.032Z"
                                transform="translate(-108.354 -156.87)"
                                fill="#4c85f7"
                              />
                              <path
                                id="Shape_94_"
                                d="M164.834,408.372v3.089a16.389,16.389,0,0,1-6.55,1.482c-.1.006-.212.006-.315.006h-.023a16.582,16.582,0,0,1-6.842-1.487v-3.089a2.288,2.288,0,0,1,2.288-2.288h1.716v2.288a2.86,2.86,0,0,0,2.86,2.86,2.505,2.505,0,0,0,.292-.017,2.856,2.856,0,0,0,2.569-2.843v-2.288h1.716A2.288,2.288,0,0,1,164.834,408.372Z"
                                transform="translate(-141.377 -379.769)"
                                fill="#4c85f7"
                              />
                              <circle
                                id="Oval_28_"
                                cx="0.572"
                                cy="0.572"
                                r="0.572"
                                transform="translate(10.299 14.873)"
                                fill="#4c85f7"
                              />
                              <path
                                id="Shape_93_"
                                d="M230.552,408.373a1.716,1.716,0,1,0,3.432,0v-2.288h-3.432Z"
                                transform="translate(-215.676 -379.77)"
                                fill="#4c85f7"
                              />
                              <path
                                id="Shape_92_"
                                d="M34.182,16.609A16.586,16.586,0,0,1,25.6,31.139V28.622a3.432,3.432,0,0,0-2.094-3.158c4.382-1.5,6.67-4.908,6.67-10.1a13.988,13.988,0,0,0-1.144-5.623V6.312a.572.572,0,0,0-1.144,0V7.667a12.257,12.257,0,0,0-10.3-5.36,12.258,12.258,0,0,0-10.3,5.36V6.312a.572.572,0,0,0-.572-.572.585.585,0,0,0-.475.252.558.558,0,0,0-.1.32V9.738a13.987,13.987,0,0,0-1.144,5.623c0,5.194,2.288,8.6,6.67,10.1a3.432,3.432,0,0,0-2.094,3.158v2.517a16.59,16.59,0,1,1,24.6-14.53Z"
                                transform="translate(-1 -0.019)"
                                fill="#4c85f7"
                              />
                              <path
                                id="Shape_91_"
                                d="M91.924,52.981c-6.521,0-11.441,5.12-11.441,11.91,0,6.522,3.85,9.828,11.441,9.828s11.441-3.307,11.441-9.828C103.366,58.1,98.446,52.981,91.924,52.981Zm-.572,2.288H92.5a.572.572,0,1,1,0,1.144H91.352a.572.572,0,1,1,0-1.144Zm-1.144,1.716h3.432a.572.572,0,1,1,0,1.144H90.208a.572.572,0,0,1,0-1.144ZM95.357,73H88.492a6.865,6.865,0,0,1,0-13.73h6.865a6.865,6.865,0,0,1,0,13.73Z"
                                transform="translate(-75.332 -49.549)"
                                fill="#4c85f7"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                    &ensp;
                    <div className="sentences">
                      {sentences?.map((sentence, index) => (
                        // Render each bullet point as a list item
                        <p key={index}>{sentence}</p>
                      ))}
                      <div className="audioplayer">
                        {summarizeAudio ? (
                          <>
                            <audio controls style={{ maxWidth: "100%" }}>
                              <source src={summarizeAudio} type="audio/mp3" />
                            </audio>
                            <div className="dropdown">
                              <button
                                type="button"
                                id="dropdownMenu1"
                                data-toggle="dropdown"
                              >
                                <BsShareFill size={20} />
                              </button>
                              <ul
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenu1"
                              >
                                <li>
                                  <p
                                    className="shareOptions"
                                    onClick={() => {
                                      setSendNumber(sentences);
                                      setIsSummarizeClick(true);
                                    }}
                                  >
                                    WhatsApp
                                  </p>
                                </li>
                                <li>
                                  <p
                                    className="shareOptions"
                                    onClick={() => {
                                      setShareDetails(summarizeAudio);
                                      setIsSummarizeClick(true);
                                    }}
                                  >
                                    Outlook
                                  </p>
                                </li>
                                <li>
                                  <p className="shareOptions">Teams</p>
                                </li>
                              </ul>
                            </div>
                          </>
                        ) : (
                          <ThreeDotSpinner />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <CommonArrowCircle
            className="mt-4 postionRelative iconHover d-flex justify-content-center marginLeft4px"
            onClick={() => setExpand((e) => !e)}
          />
        </form>
      </div>
      <div className="mainContent dashboardQueryTop">
        {data.length === 0 ? (
          <>
            {typeof sessionName !== "string" ? (
              loading1 ? (
                <CommonLoader />
              ) : (
                <ChatBot userName={filterContent?.userName} />
              )
            ) : (
              <div className="history">
                <div className="defaultPlaceholder">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="69"
                    height="69"
                    viewBox="0 0 69 69"
                    fill="none"
                  >
                    <path
                      d="M34.4037 0C15.4022 0 0 15.4022 0 34.4037C0 53.4051 15.4022 68.8073 34.4037 68.8073C53.4051 68.8073 68.8073 53.4051 68.8073 34.4037C68.8073 15.4022 53.4051 0 34.4037 0ZM34.4037 63.4318C18.3714 63.4318 5.37557 50.4359 5.37557 34.4037C5.37557 18.3714 18.3714 5.37557 34.4037 5.37557C50.4359 5.37557 63.4318 18.3714 63.4318 34.4037C63.4318 50.4359 50.4359 63.4318 34.4037 63.4318Z"
                      fill="#1C274C"
                    />
                    <path
                      d="M42.9783 18.8717L46.414 28.3343H43.2985L39.0858 23.2226L38.9845 23.0997L38.8542 23.1912L31.5277 28.3343H25.4014C22.8746 28.3343 20.8257 30.4251 20.8257 32.9908V37.4879L17.4507 28.1878L17.4506 28.1875C17.2191 27.5539 17.5252 26.8781 18.1067 26.6655L41.4977 18.1283L41.4981 18.1281C42.0784 17.9146 42.748 18.2375 42.9783 18.8717L42.9783 18.8717ZM50.2942 31.8262L50.2945 31.8262C50.4459 31.826 50.5959 31.856 50.7356 31.9146C50.8753 31.9731 51.0019 32.059 51.108 32.1671C51.2141 32.2753 51.2975 32.4035 51.3533 32.5444C51.4091 32.6852 51.4362 32.8357 51.433 32.9872H51.433V32.9908V49.1949C51.433 49.8721 50.9135 50.4042 50.2942 50.4042H25.4014C24.7841 50.4042 24.2643 49.8723 24.2627 49.1947C24.2627 49.1947 24.2627 49.1946 24.2627 49.1945L24.2627 32.9908H24.2627L24.2626 32.9872C24.2594 32.8357 24.2865 32.6852 24.3424 32.5444C24.3982 32.4035 24.4815 32.2753 24.5876 32.1671C24.6937 32.059 24.8203 31.9731 24.96 31.9146C25.0997 31.856 25.2497 31.826 25.4012 31.8262H25.4014L50.2942 31.8262ZM50.2942 31.6542H25.4014H50.2942ZM48.1646 47.3079H48.3366V47.1359V41.9753V41.9437L48.3254 41.9142L46.0685 35.9812L46.0084 35.8232L45.8493 35.8806L40.1073 37.9534L40.0702 37.9668L40.0426 37.9951L35.709 42.4354L31.0692 36.6759L30.9003 36.4662L30.781 36.7076L27.3682 43.6193L27.3504 43.6553V43.6955V47.1359V47.3079H27.5224H48.1646Z"
                      fill="#1C274C"
                      stroke="#9789F1"
                      stroke-width="0.344037"
                    />
                  </svg>
                  <p className="mt-4">
                    Output of the query will be shown here{" "}
                  </p>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="prompt">
            {data &&
              data?.length > 0 &&
              data?.map((e, i) => (
                <CommonPromChat
                  indexValue={i}
                  key={i + 1}
                  user={e.user}
                  isDashboardSplit
                  AI={e.AI}
                  audio={audio}
                  thumbsUp={data[i].thumbsUp}
                  thumbsDown={data[i].thumbsDown}
                  onThumbsUpClick={() => handleThumbsUpClick(i)}
                  onThumbsDownClick={() => handleThumbsDownClick(e, i)}
                  onShareClick={() => {
                    setShareDetails(e);
                    setCurrentIdx(i);
                    setIsSummarizeClick(false);
                  }}
                  onShareNumberClick={() => {
                    setSendNumber(e);
                    setIsSummarizeClick(false);
                  }}
                  
                  updateEvaluateData={(data) =>
                    handleMergeEvaluageData(data, e?.id)
                  }
                  listData={e}
                  hidePayload={2}
                />
              ))}
          </div>
        )}
        {generating && <CommonGenerateBtn />}
        <SearchInputArrowBtn
          value={inputValue}
          placeholder={commonStr.typeYourQuery}
          onKeyPress={handleKeyPress}
          onChange={(e) => setInputValue(e.target.value)}
          inputValue={inputValue}
          onClick={generatePrompt}
          handleRecordingData={handleRecordingData}
          handleToStopAudio={handleToStopAudio}
        />
      </div>

      {shareModal && (
        <CommonSendMail
          show={shareModal}
          onSubmit={share}
          closeShareModal={closeShareModal}
          handleInputChange={handleInputChange}
          formData={formData}
        />
      )}
      {numberModal && (
        <CommonShareWhatsApp
          show={numberModal}
          onSubmit={shareToWhatsApp}
          closeShareModal={closePhoneNumberModal}
          handleInputChange={handleInputNumberChange}
          formData={formNumberData}
        />
      )}

      {RejectModal && (
        <CommonFeedback
          show={RejectModal}
          onSubmit={submitRejectModal}
          onchangeTextArea={handleChangeTextArea}
          onchangeTextAreaValue={feedbackInput}
          closeRejectModal={closeRejectModal}
        />
      )}
    </div>
  );
};

export default ExicistingDashboard;
