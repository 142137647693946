import React from "react";
import { Table } from "rsuite";

function LogoTable({ logoDataSrc, currPageLogo }) {
  const { Column, HeaderCell, Cell } = Table;

  const filteredClaims = logoDataSrc
    .filter((claim) => parseInt(claim.page_number) === currPageLogo)
    .map((claim) => {
      return {
        "Potential Issue": claim.response,
        Page: claim.page_number,
      };
    });

  return (
    <div>
      <h5 style={{ color: "black", marginBottom: "20px" }}>Logo Table</h5>
      {filteredClaims.length > 0 ? (
        <Table
          wordWrap="break-word"
          data={filteredClaims}
          height={200}
          width={370}
          virtualized
          rowHeight={5}
          loading={filteredClaims.length === 0}
          className="personal_tableRed"
          style={{
            scrollbarWidth: "0",
            border: "1px solid #ffdcdc",
          }}
        >
          {Object.keys(filteredClaims[0]).map((key, index) => (
            <Column key={index} flexGrow={2} align="center">
              <HeaderCell style={{ fontSize: "14px" }}>{key}</HeaderCell>
              <Cell style={{ fontSize: "13px" }} dataKey={key} />
            </Column>
          ))}
        </Table>
      ) : (
        <p style={{ color: "red" }}>No response found on this page.</p>
      )}
    </div>
  );
}

export default LogoTable;
