import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  audioGenerate,
  dataAudioHistory,
  feedBack,
  promptGenerate,
  sendEmail,
  sendDetailInWhatsApp,
  generateAudio,
} from "../../api/Postaction";
import { getUrl, postUrl } from "../../api/Endpoint";

import { MyContext } from "../../context/ContextApi";
import {
  ChatBot,
  CommonFeedback,
  CommonGenerateBtn,
  CommonLoader,
  CommonPromChat,
  CommonSendMail,
  CommonShareWhatsApp,
  SearchInputArrowBtn,
} from "../../common";
import { errorStr } from "../../utils/constants/errorStrings";
import { showToast } from "../../utils/helpers/helpers";
import axiosApi from "../../interceptors/axiosinterceptor";

const ExicistingChat = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { dropDownPersona, sessionPersonaVal, faqInputValue } =
    useContext(MyContext);
  let state = location.state;
  const [inputValue, setInputValue] = useState(
    state?.inputValue ? state?.inputValue : ""
  );
  const [shareModal, setShareModal] = useState(false);
  const [numberModal, setNumberModal] = useState(false);
  const [RejectModal, setRejectModal] = useState(false);
  const [RejectAnswer, setRejectAnswer] = useState();
  const [generating, setGenerating] = useState(false);
  const [tables, setTables] = useState([]);
  const [shareDetails, setShareDetails] = useState();
  const [sendNumber, setSendNumber] = useState();
  const [data, setData] = useState([]);
  const [updation, setUpdation] = useState(true);
  const [loading, setLoading] = useState(false);
  const [audio, setAudio] = useState([]);
  let userEmail = sessionStorage.getItem("email");
  const [audioData, setAudioData] = useState(null);

  const [feedbackInput, setFeedbackInput] = useState("");
  const [currentIdx, setCurrentIdx] = useState("");
  const [formData, setFormData] = useState({
    notes: "",
    emails: "",
  });

  const [formNumberData, setFormNumberData] = useState({
    number: "",
    text: "",
  });

  let currentPath = location.pathname.substring(1);
  if (currentPath.includes("%20")) {
    currentPath = currentPath.replace("%20", " ");
  }
  currentPath = currentPath.split("/")[1];

  //input set Faq Value
  useEffect(() => {
    // if (faqInputValue) {
      setInputValue(faqInputValue);
    // }
  }, [faqInputValue]);

  const AcccessTables = async () => {
    setLoading(true);

    try {
      setData([]);
      let res = await axiosApi.get(`${getUrl.tableAccess}${userEmail}/`);
      if (state === null) {
        if (sessionPersonaVal) {
          let chat = await axiosApi.post(`${postUrl.sessionHistory}`, {
            email_id: userEmail,
            session_id: currentPath,
            persona_name: sessionPersonaVal,
          });
          setLoading(false);
          let a = chat.data;
          let b = a.map((e) => ({
            user: e.user,
            AI: e.AI,
            audio: e.AI_Audio,
            Message_Id: e.Message_Id,
            thumbsUp: e.Feedback === "thumbs-up" ? true : false,
            thumbsDown: e.Feedback === "thumbs-down" ? true : false,
          }));

          let audioList = a.map((e) => ({
            audio: e.AI_Audio,
            Message_Id: e.Message_Id,
          }));

          setData(b);
          setAudio(audioList);
        } else {
          setData([]);
          setLoading(false);
        }
      } else {
        setLoading(false);
        if (state.sessionslist.includes(state.chatsessionId)) {
          toast.error("Session Name Already Exists", {
            autoClose: 1000,
            toastId: "hi",
          });
          navigate("/newquery");
        }
      }

      let data1 = res.data.assigned_tables;
      setTables(data1);
    } catch (err) {
      console.log("Error in getting Accessable Table", err);
    }
  };
  const share = async (e) => {
    setShareModal(false);
    e.preventDefault();
    let body = {
      Note: formData.notes,
      sender: userEmail,
      recipients: [formData.emails],
      insight: shareDetails.AI,
      file_path: audio[currentIdx].audio,
    };
    await sendEmail(body);
    setShareDetails();
  };
  const closeShareModal = () => {
    setShareModal(false);
    setShareDetails();
  };
  useEffect(() => {
    if (shareDetails !== undefined) {
      setFormData({
        notes: "",
        emails: "",
      });
      setShareModal(true);
    }
  }, [shareDetails]);

  //Send response in WhatsApp
  const shareToWhatsApp = async (e) => {
    setNumberModal(false);
    e.preventDefault();
    let body = {
      recipient_id: formNumberData.number,
      text: sendNumber.user + "\n" + sendNumber.AI,
    };
    await sendDetailInWhatsApp(body);
    setSendNumber();
  };
  const closePhoneNumberModal = () => {
    setNumberModal(false);
    setSendNumber();
  };
  useEffect(() => {
    if (sendNumber !== undefined) {
      setFormNumberData({
        number: "",
        text: "",
      });
      setNumberModal(true);
    }
  }, [sendNumber]);

  useEffect(() => {
    // setUpdation(true);
    AcccessTables();
  }, [currentPath]);

  const generatePrompt = async () => {
    if (dropDownPersona) {
      if (inputValue !== "") {
        setInputValue("");
        setGenerating(true);
        try {
          let a = await promptGenerate(
            userEmail,
            currentPath,
            inputValue,
            dropDownPersona,
            sessionPersonaVal
          );
          let b;
          if (a !== undefined) {
            console.log("adata", JSON.stringify(a));
            if (a?.status_code === 200) {
              setData((prev) => [
                ...prev,
                {
                  user: inputValue,
                  AI: a.summary,
                  thumbsUp: false,
                  thumbsDown: false,
                  Message_Id: "",
                },
              ]);
              setGenerating(false);
              b = await audioGenerate(a.summary);
            } else {
              setGenerating(false);
              toast.error("Error in generating prompt");
              b = "";
            }
            let value = {
              email_id: userEmail,
              session_id: currentPath,
              s3_url: b,
              persona_name: sessionPersonaVal,
            };
            let id = await dataAudioHistory(value);

            setAudio((prev) => [
              ...prev,
              {
                audio: b,
                Message_Id: id,
              },
            ]);
          }
        } catch (err) {
          setGenerating(false);
          console.log(err);
        } finally {
          setGenerating(false);
        }
      }
    } else {
      toast.error(errorStr.selectPersona);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      generatePrompt();
    }
  };

  const handleThumbsUpClick = async (index) => {
    setRejectAnswer();
    const newThumbsStates = [...data];
    newThumbsStates[index].thumbsUp = !newThumbsStates[index].thumbsUp;
    newThumbsStates[index].thumbsDown = false; // Reset thumbs down
    setData(newThumbsStates);
    let a = data[index];

    let body = {
      message_id: audio[index].Message_Id,
      question: a.user,
      response: a.AI,
      feedback: "thumbs-up",
      // email_id: userEmail,
      // session_id: currentPath,
    };

    let feedbackStatus = await feedBack(body);
    if (feedbackStatus?.status === 200) {
      showToast(feedbackStatus?.data?.message, 1);
    }
  };

  const handleThumbsDownClick = (e, index) => {
    setFeedbackInput("");
    let msgId = audio[index].Message_Id;
    let mergeData = {
      ...e,
      Message_Id: msgId,
    };
    setRejectModal(true);
    setRejectAnswer(mergeData);
    const newThumbsStates = [...data];
    newThumbsStates[index].thumbsDown = !newThumbsStates[index].thumbsDown;
    newThumbsStates[index].thumbsUp = false; // Reset thumbs up
    setData(newThumbsStates);
  };
  const closeRejectModal = () => {
    let a = data.filter((e) => e.user === RejectAnswer.user)[0];
    const indexOfObjectToUpdate = data.findIndex(
      (item) => item.user === a.user
    );
    if (indexOfObjectToUpdate !== -1) {
      data[indexOfObjectToUpdate].thumbsUp = false;
      data[indexOfObjectToUpdate].thumbsDown = false;
    }
    setRejectAnswer();
    setRejectModal(false);
  };
  const submitRejectModal = async (e, index) => {
    e.preventDefault();
    let body = {
      message_id: RejectAnswer.Message_Id,
      question: RejectAnswer.user,
      response: RejectAnswer.AI,
      feedback: "thumbs-down",
      comment: feedbackInput,
      // email_id: userEmail,
      // session_id: currentPath,
    };
    setRejectAnswer();
    setRejectModal(false);

    let feedbackStatus = await feedBack(body);
    if (feedbackStatus?.status === 200) {
      showToast(feedbackStatus?.data?.message, 1);
      setFeedbackInput("");
    }
  };

  const handleRecordingData = (data) => {
    if (data) {
      setAudioData(data);
    }
  };

  const handleToStopAudio = async () => {
    if (dropDownPersona) {
      if (audioData) {
        setAudioData("");
        setGenerating(true);

        try {
          let convertedData = await generateAudio(audioData);

          let audioResponse = await promptGenerate(
            userEmail,
            currentPath,
            convertedData,
            dropDownPersona,
            sessionPersonaVal
          );

          let sectionData;
          if (audioResponse !== undefined) {
            if (audioResponse?.status === 200) {
              setData((prev) => [
                ...prev,
                {
                  user: convertedData,
                  AI: audioResponse,
                  thumbsUp: false,
                  thumbsDown: false,
                  Message_Id: "",
                },
              ]);
              setGenerating(false);
              sectionData = await audioGenerate(audioResponse);
            } else {
              setGenerating(false);
              toast.error("Error in generating prompt");
              sectionData = "";
            }
            let value = {
              email_id: userEmail,
              session_id: currentPath,
              s3_url: sectionData,
              persona_name: sessionPersonaVal,
            };
            let id = await dataAudioHistory(value);
            setAudio((prev) => [
              ...prev,
              {
                audio: sectionData,
                Message_Id: id,
              },
            ]);
          }
        } catch (err) {
          setGenerating(false);
          console.log(err);
        }
      }
    } else {
      toast.error(errorStr.selectPersona);
    }
  };

  useEffect(() => {
    if (audioData !== null) {
      handleToStopAudio();
    }
  }, [audioData]);

  //handleToChangeTextArea
  const handleChangeTextArea = (e) => {
    setFeedbackInput(e.target.value);
  };

  //handleInputChange
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleInputNumberChange = (e) => {
    const { name, value } = e.target;
    setFormNumberData({
      ...formNumberData,
      [name]: value,
    });
  };

  const handleMergeEvaluageData = (evaluateData, id) => {
    let duplicateData = [...data];
    let mergeData = duplicateData.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          evaluate: evaluateData,
        };
      }
      return item;
    });
    setData(mergeData);
  };

  return (
    <div className="mainContent">
      {data.length > 0 ? (
        <div className="prompt">
          {data &&
            data?.length > 0 &&
            data?.map((e, i) => (
              <CommonPromChat
                key={i}
                indexValue={i}
                user={e.user}
                AI={e.AI}
                audio={audio}
                thumbsUp={data[i].thumbsUp}
                thumbsDown={data[i].thumbsDown}
                onThumbsUpClick={() => handleThumbsUpClick(i)}
                onThumbsDownClick={() => handleThumbsDownClick(e, i)}
                onShareClick={() => {
                  setShareDetails(e);
                  setCurrentIdx(i);
                }}
                onShareNumberClick={() => {
                  setSendNumber(e);
                }}
                audioVisble={true}
                updateEvaluateData={(data) =>
                  handleMergeEvaluageData(data, e?.id)
                }
                listData={e}
                hidePayload={3}
              />
            ))}
        </div>
      ) : (
        <>
          {state === null ? (
            loading ? (
              <CommonLoader />
            ) : (
              <ChatBot userName={userEmail?.split("@")[0]} />
            )
          ) : (
            <div className="history">
              <div className="defaultPlaceholder">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="71"
                  height="71"
                  viewBox="0 0 71 71"
                  fill="none"
                >
                  <path
                    d="M35.0468 0C15.6901 0 0 15.6901 0 35.0467C0 54.4033 15.6901 70.0935 35.0468 70.0935C54.4034 70.0935 70.0935 54.4033 70.0935 35.0467C70.0935 15.6901 54.4034 0 35.0468 0ZM35.0468 64.6174C18.7148 64.6174 5.47606 51.3786 5.47606 35.0467C5.47606 18.7148 18.7148 5.47605 35.0468 5.47605C51.3787 5.47605 64.6175 18.7148 64.6175 35.0467C64.6175 51.3786 51.3787 64.6174 35.0468 64.6174Z"
                    fill="#1C274C"
                  />
                  <path
                    d="M52.5702 28.4525C52.5702 22.4181 46.6857 17.5239 39.4277 17.5239C32.1719 17.5239 26.2852 22.4181 26.2852 28.4525C26.2852 34.489 32.1719 39.381 39.4277 39.381C41.8213 39.381 44.6855 38.3179 44.6855 38.3179L51.9156 41.612L50.3776 34.5915C50.3776 34.5915 52.5702 31.1242 52.5702 28.4525ZM44.7753 35.95L43.9239 36.2623C43.2266 36.519 41.0554 37.1886 39.4276 37.1886C33.389 37.1886 28.4754 33.2698 28.4754 28.4526C28.4754 23.6355 33.389 19.7167 39.4276 19.7167C45.4661 19.7167 50.3775 23.6355 50.3775 28.4526C50.3775 29.9221 49.2459 32.2794 48.5271 33.4217L48.048 34.1811L48.8394 37.8026L44.7753 35.95Z"
                    fill="#1C274C"
                  />
                  <path
                    d="M30.666 48.1473C29.0381 48.1473 26.867 47.4777 26.1675 47.221L25.3226 46.9087L21.254 48.7611L22.0454 45.1397L21.5663 44.3803C20.8497 43.2402 19.7139 40.8807 19.7139 39.4112C19.7139 36.348 21.7096 33.6591 24.7108 32.0998C24.4712 31.3896 24.2916 30.6581 24.1931 29.9072C20.2144 31.7832 17.5234 35.3341 17.5234 39.4112C17.5234 42.0808 19.7139 45.5504 19.7139 45.5504L18.178 52.5708L25.408 49.2767C25.408 49.2767 28.2723 50.3399 30.6658 50.3399C37.2949 50.3399 42.7645 46.1964 43.663 40.9301C42.9785 41.1141 42.1635 41.2917 41.2992 41.4157C40.1549 45.2659 35.8382 48.1473 30.666 48.1473Z"
                    fill="#1C274C"
                  />
                  <ellipse
                    cx="33.7"
                    cy="27.7"
                    rx="1.7"
                    ry="1.7"
                    fill="#1C274C"
                  />
                  <ellipse
                    cx="38.7996"
                    cy="27.7"
                    rx="1.7"
                    ry="1.7"
                    fill="#1C274C"
                  />
                  <ellipse
                    cx="43.8992"
                    cy="27.7"
                    rx="1.7"
                    ry="1.7"
                    fill="#1C274C"
                  />
                  <ellipse
                    cx="25.7"
                    cy="39.7"
                    rx="1.7"
                    ry="1.7"
                    fill="#1C274C"
                  />
                  <ellipse
                    cx="30.7996"
                    cy="39.7"
                    rx="1.7"
                    ry="1.7"
                    fill="#1C274C"
                  />
                  <path
                    d="M35.8992 41.57C36.932 41.57 37.7692 40.7328 37.7692 39.7C37.7692 38.6672 36.932 37.83 35.8992 37.83C34.8664 37.83 34.0292 38.6672 34.0292 39.7C34.0292 40.7328 34.8664 41.57 35.8992 41.57Z"
                    fill="#1C274C"
                    stroke="white"
                    stroke-width="0.34"
                  />
                </svg>
                <p>Output of the query will be shown here </p>
              </div>
            </div>
          )}
        </>
      )}
      {generating && <CommonGenerateBtn />}
      <SearchInputArrowBtn
        value={inputValue}
        placeholder="Type your query here"
        onKeyPress={handleKeyPress}
        onChange={(e) => setInputValue(e.target.value)}
        inputValue={inputValue}
        onClick={generatePrompt}
        handleRecordingData={handleRecordingData}
        handleToStopAudio={handleToStopAudio}
      />

      {shareModal && (
        <CommonSendMail
          show={shareModal}
          onSubmit={share}
          closeShareModal={closeShareModal}
          handleInputChange={handleInputChange}
          formData={formData}
        />
      )}

      {numberModal && (
        <CommonShareWhatsApp
          show={numberModal}
          onSubmit={shareToWhatsApp}
          closeShareModal={closePhoneNumberModal}
          handleInputChange={handleInputNumberChange}
          formData={formNumberData}
        />
      )}

      {RejectModal && (
        <CommonFeedback
          show={RejectModal}
          onSubmit={submitRejectModal}
          onchangeTextArea={handleChangeTextArea}
          onchangeTextAreaValue={feedbackInput}
          closeRejectModal={closeRejectModal}
        />
      )}
    </div>
  );
};

export default ExicistingChat;
