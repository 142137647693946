import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  audioGenerate,
  documentAudioHistory,
  documentPrompt,
  feedBack,
  sendEmail,
  sendDetailInWhatsApp,
  generateAudioForDoc,
} from "../../api/Postaction";
import { postUrl } from "../../api/Endpoint";
import { useNavigate } from "react-router-dom";
import { MyContext } from "../../context/ContextApi";

import { getDocumentPayload, showToast } from "../../utils/helpers/helpers";
import {
  ChatBot,
  CommonFeedback,
  CommonGenerateBtn,
  CommonLoader,
  CommonPromChat,
  CommonSendMail,
  CommonShareWhatsApp,
  SearchInputArrowBtn,
  Sourcebar,
} from "../../common";
import { commonStr } from "../../utils/constants/commonStrings";
import axiosApi from "../../interceptors/axiosinterceptor";
import { errorStr } from "../../utils/constants/errorStrings";

const ExcistingDocument = () => {
  const { allValues, faqInputValue } = useContext(MyContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [sourceData, setSourceData] = useState();
  const [shareModal, setShareModal] = useState(false);
  const [numberModal, setNumberModal] = useState(false);
  const [loading, setLoading] = useState(false);
  let state = location.state;
  const [RejectModal, setRejectModal] = useState(false);
  const [RejectAnswer, setRejectAnswer] = useState();
  const [inputValue, setInputValue] = useState("");
  const [generating, setGenerating] = useState(false);
  const [shareDetails, setShareDetails] = useState();
  const [sendNumber, setSendNumber] = useState();
  const [data, setData] = useState([]);
  const [audioData, setAudioData] = useState(null);
  const [feedbackInput, setFeedbackInput] = useState("");
  const [currentIdx, setCurrentIdx] = useState("");
  const [audio, setAudio] = useState([]);
  const [formData, setFormData] = useState({
    notes: "",
    emails: "",
  });
  const [formNumberData, setFormNumberData] = useState({
    number: "",
    text: "",
  });
  let userEmail = sessionStorage.getItem("email");

  let currentPath = location.pathname.split("/")[2];

  if (currentPath.includes("%20")) {
    currentPath = currentPath.replace("%20", " ");
  }

  //input set Faq Value
  useEffect(() => {
    // if (faqInputValue) {
      setInputValue(faqInputValue);
    // }
  }, [faqInputValue]);

  const share = async (e) => {
    setShareModal(false);
    e.preventDefault();
    let res = new FormData(e.target);
    let value = [...res.entries()];
    let recipientsemail = value[1][1];
    let body = {
      Note: value[0][1],
      recipients: recipientsemail.split(","),
      insight: shareDetails.AI,
      file_path: audio[currentIdx].audio,
      sender: userEmail,
    };
    await sendEmail(body);
    setShareDetails();
  };
  const closeShareModal = () => {
    setShareModal(false);
    setShareDetails();
  };
  useEffect(() => {
    if (shareDetails !== undefined) {
      setFormData({
        notes: "",
        emails: "",
      });
      setShareModal(true);
    }
  }, [shareDetails]);

  // send response in whatsApp
  const shareToWhatsApp = async (e) => {
    setNumberModal(false);
    e.preventDefault();
    let body = {
      recipient_id: formNumberData.number,
      text: sendNumber.user + "\n" + sendNumber.AI,
    };
    await sendDetailInWhatsApp(body);
    setSendNumber();
  };
  const closePhoneNumberModal = () => {
    setNumberModal(false);
    setSendNumber();
  };
  useEffect(() => {
    if (sendNumber !== undefined) {
      setFormNumberData({
        number: "",
        text: "",
      });
      setNumberModal(true);
    }
  }, [sendNumber]);

  const history = async () => {
    if (allValues !== undefined) {
      setLoading(true);
      try {
        setSourceData();
        setData([]);
        if (state === null) {
          let chat = await axiosApi.post(`${postUrl.documentChathistory}`, {
            email_id: userEmail,
            session_id: currentPath,
            collection_name: allValues?.collection,
          });
          setLoading(false);
          let a = chat.data;
          let b = a.map((e, index) => ({
            id: index + 1,
            user: e.user,
            AI: e.AI,
            audio: e.AI_Audio,
            source: e.AI_Relevant_Docs,
            thumbsUp: e.Feedback === "thumbs-up" ? true : false,
            thumbsDown: e.Feedback === "thumbs-down" ? true : false,
            evaluate: e.Evaluation_response,
          }));
          let audioList = a.map((e) => ({
            audio: e.AI_Audio,
            Message_Id: e.Message_Id,
          }));
          setData(b);
          setAudio(audioList);
        } else {
          setData([]);
          setLoading(false);
          if (state.documentsessionslist.includes(state.sessionId)) {
            showToast(errorStr.sessionNameAlready);
            navigate("/documentquery");
          }
          setData([]);
        }
      } catch (err) {
        console.log("Error in getting Accessable Table", err);
      }
    } else {
      navigate("/documentquery");
    }
  };
  useEffect(() => {
    history();
  }, [currentPath, state]);

  const generatePrompt = async () => {
    if (allValues !== undefined) {
      if (inputValue === "") {
        showToast(errorStr.pleaseEnterQuery);
      } else {
        setInputValue("");
        setGenerating(true);
        let body = {
          string_to_search: inputValue,
          email_id: userEmail,
          session_id: currentPath,
          collection_name: allValues?.collection,
          ...getDocumentPayload(true),
        };

        try {
          let a = await documentPrompt(body);
          let b;
          if (a !== undefined) {
            setData((prev) => [
              ...prev,
              {
                id: data.length + 1,
                user: inputValue,
                AI: a[0],
                source: a[2],
                thumbsUp: false,
                thumbsDown: false,
              },
            ]);
            b = await audioGenerate(a[0]);
            let value = {
              email_id: userEmail,
              session_id: currentPath,
              s3_url: b,
              collection: allValues?.collection,
            };
            let id = await documentAudioHistory(value);
            setAudio((prev) => [
              ...prev,
              {
                audio: b,
                Message_Id: id,
              },
            ]);

            setSourceData({
              id: data.length + 1,
              user: inputValue,
              AI: a[0],
              source: a[2],
              rating: "",
            });
          }

          setGenerating(false);
        } catch (err) {
          console.log("error on generating prompt", err);
        }
      }
    } else {
      showToast(errorStr.pleaseSelectCollection);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      generatePrompt();
    }
  };

  const handleThumbsUpClick = async (index) => {
    setRejectAnswer();
    const newThumbsStates = [...data];
    newThumbsStates[index].thumbsUp = !newThumbsStates[index].thumbsUp;
    newThumbsStates[index].thumbsDown = false; // Reset thumbs down
    setData(newThumbsStates);
    let a = data[index];
    let body = {
      message_id: audio[index].Message_Id,
      question: a.user,
      response: a.AI,
      feedback: "thumbs-up",
      email_id: userEmail,
      session_id: currentPath,
    };
    let feedbackStatus = await feedBack(body);
    if (feedbackStatus?.status === 200) {
      showToast(feedbackStatus?.data?.message, 1);
      setFeedbackInput("");
    }
  };

  const handleThumbsDownClick = (e, index) => {
    let msgId = audio[index].Message_Id;
    let mergeData = {
      ...e,
      Message_Id: msgId,
    };
    setRejectModal(true);
    setRejectAnswer(mergeData);
    setFeedbackInput("");
    const newThumbsStates = [...data];
    newThumbsStates[index].thumbsDown = !newThumbsStates[index].thumbsDown;
    newThumbsStates[index].thumbsUp = false; // Reset thumbs up
    setData(newThumbsStates);
  };
  const closeRejectModal = () => {
    let a = data.filter((e) => e.user === RejectAnswer.user)[0];
    const indexOfObjectToUpdate = data.findIndex(
      (item) => item.user === a.user
    );
    if (indexOfObjectToUpdate !== -1) {
      data[indexOfObjectToUpdate].thumbsUp = false;
      data[indexOfObjectToUpdate].thumbsDown = false;
    }
    setRejectAnswer();
    setRejectModal(false);
  };
  const submitRejectModal = async (e) => {
    e.preventDefault();
    let body = {
      message_id: RejectAnswer.Message_Id,
      question: RejectAnswer.user,
      response: RejectAnswer.AI,
      feedback: "thumbs-down",
      comment: feedbackInput,
      email_id: userEmail,
      session_id: currentPath,
    };
    setRejectAnswer();
    setRejectModal(false);
    let feedbackStatus = await feedBack(body);
    if (feedbackStatus?.status === 200) {
      showToast(feedbackStatus?.data?.message, 1);
      setFeedbackInput("");
    }
  };

  //handleRecordData
  const handleRecordingData = (data) => {
    if (data) {
      setAudioData(data);
    }
  };

  const handleToStopAudio = async () => {
    if (allValues !== undefined) {
      if (audioData) {
        setAudioData("");
        setGenerating(true);

        try {
          let convertedData = await generateAudioForDoc(audioData);

          let body = {
            string_to_search: convertedData,
            email_id: userEmail,
            session_id: currentPath,
            collection_name: allValues.collection,
            ...getDocumentPayload(true),
          };

          let audioRes = await documentPrompt(body);
          let sectionData;

          if (audioRes !== undefined) {
            setGenerating(false);
            sectionData = await audioGenerate(audioRes[0]);
            setData((prev) => [
              ...prev,
              {
                id: data.length + 1,
                user: convertedData,
                AI: audioRes[0],
                source: audioRes[2],
                thumbsUp: false,
                thumbsDown: false,
              },
            ]);

            let value = {
              email_id: userEmail,
              session_id: currentPath,
              s3_url: sectionData,
              collection: allValues?.collection,
            };

            let id = await documentAudioHistory(value);

            setAudio((prev) => [
              ...prev,
              {
                audio: sectionData,
                Message_Id: id,
              },
            ]);
            setSourceData({
              id: data.length + 1,
              user: convertedData,
              AI: audioRes[0],
              source: audioRes[2],
              rating: "",
            });
          }
          setGenerating(false);
        } catch (err) {
          console.log("error on generating prompt", err);
        }
      }
    } else {
      showToast(errorStr.pleaseSelectCollection);
    }
  };

  useEffect(() => {
    if (audioData !== null) {
      handleToStopAudio();
    }
  }, [audioData]);

  //handleToChangeTextArea
  const handleChangeTextArea = (e) => {
    setFeedbackInput(e.target.value);
  };
  //handleInputChange
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleInputNumberChange = (e) => {
    const { name, value } = e.target;
    setFormNumberData({
      ...formNumberData,
      [name]: value,
    });
  };

  const handleMergeEvaluageData = (evaluateData, id) => {
    let duplicateData = [...data];
    let mergeData = duplicateData.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          evaluate: evaluateData,
        };
      }
      return item;
    });
    setData(mergeData);
  };

  return (
    <div className="d-flex justify-content-between postionRelative">
      <div className="mainContent w-100">
        {data?.length > 0 ? (
          <div className="prompt">
            {data &&
              data?.length > 0 &&
              data?.map((e, i) => (
                <CommonPromChat
                  listData={e}
                  indexValue={i}
                  key={i + 1}
                  user={e.user}
                  AI={e.AI}
                  audio={audio}
                  thumbsUp={data[i].thumbsUp}
                  thumbsDown={data[i].thumbsDown}
                  onThumbsUpClick={() => handleThumbsUpClick(i)}
                  onThumbsDownClick={() => handleThumbsDownClick(e, i)}
                  onShareClick={() => {
                    setShareDetails(e);
                    setCurrentIdx(i);
                  }}
                  onShareNumberClick={() => {
                    setSendNumber(e);
                  }}
                  isViewSource={e?.id !== sourceData?.id}
                  onClickViewSource={() => setSourceData(e)}
                  updateEvaluateData={(data) =>
                    handleMergeEvaluageData(data, e?.id)
                  }
                />
              ))}
          </div>
        ) : (
          <>
            {loading ? (
              <CommonLoader />
            ) : (
              <ChatBot userName={userEmail?.split("@")[0]} />
            )}
          </>
        )}
        {generating && <CommonGenerateBtn title={commonStr.generating} />}

        <SearchInputArrowBtn
          value={inputValue}
          placeholder={commonStr.typeYourQuery}
          onKeyPress={handleKeyPress}
          onChange={(e) => setInputValue(e.target.value)}
          inputValue={inputValue}
          onClick={generatePrompt}
          handleRecordingData={handleRecordingData}
          handleToStopAudio={handleToStopAudio}
        />

        {shareModal && (
          <CommonSendMail
            show={shareModal}
            onSubmit={share}
            closeShareModal={closeShareModal}
            handleInputChange={handleInputChange}
            formData={formData}
          />
        )}
        {numberModal && (
          <CommonShareWhatsApp
            show={numberModal}
            onSubmit={shareToWhatsApp}
            closeShareModal={closePhoneNumberModal}
            handleInputChange={handleInputNumberChange}
            formData={formNumberData}
          />
        )}

        {RejectModal && (
          <CommonFeedback
            show={RejectModal}
            onSubmit={submitRejectModal}
            onchangeTextArea={handleChangeTextArea}
            onchangeTextAreaValue={feedbackInput}
            closeRejectModal={closeRejectModal}
          />
        )}

        {/* For Reject Message */}
      </div>
      <Sourcebar data1={sourceData} />
    </div>
  );
};

export default ExcistingDocument;
