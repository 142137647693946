import React from "react";
import faq_img from "../../assets/sideBar/faq .png";

function faq({ onClick }) {
  return (
    <div
      className="mt-1 px-1 py-2"
      style={{
        display: "flex",
        alignItems: "center",
        borderTop: "1px solid #C1C1C1",
      }}
      onClick={onClick}
    >
      <img
        style={{
          width: "30px",
          height: "30px",
          color: "whitesmoke",
          cursor: "pointer",
        }}
        src={faq_img}
        alt="faq"
      />
      <div style={{ marginLeft: "10px", cursor: "pointer" }}>FAQs</div>
    </div>
  );
}

export default faq;
