import React from "react";
import { Table } from "rsuite";

const ClaimsTagTable = ({ claimsDataSrc, currentPage }) => {
  const { Column, HeaderCell, Cell } = Table;

  const filteredClaims = claimsDataSrc
  .filter((claim) => claim["page_no"] === currentPage)
  .map((claim) => {
    return {
      "Approved Claim": claim.tag,
      "Content Text": claim.content,
      "Type": claim.type
    };
  });

  return (
    <div>
      <h5 style={{ color: "black", marginBottom: '20px' }}>Claims Tag Table</h5>
      {filteredClaims.length > 0 ? (
        <Table
          wordWrap="break-word"
          data={filteredClaims}
          height={500}
          width={370}
          virtualized
          rowHeight={5}
          loading={filteredClaims.length === 0}
          className="personal_tableRed"
          style={{
            scrollbarWidth: "0",
            border: "1px solid #ffdcdc",
          }}
        >
          {Object.keys(filteredClaims[0]).map((key, index) => (
            <Column key={index} flexGrow={2} align="center">
              <HeaderCell style={{ fontSize: "12.5px", height: '50px', lineHeight: '13px' }}>{key}</HeaderCell>
              <Cell style={{ fontSize: "13px" }} dataKey={key} />
            </Column>
          ))}
        </Table>
      ) : (
        <p style={{color: 'red'}}>No claims found on this page.</p>
      )}
    </div>
  );
};

export default ClaimsTagTable;
