import React, { useState, useContext, useRef, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import Plot from 'react-plotly.js';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  getTexttoDashboard,
  updateChartResponseInHistory,
} from "../../api/Postaction";
import uuid from "react-uuid";
import { ChatBot } from '../../common';
// import bot from "../../assets/svg/bot.svg";
import { toast } from "react-toastify";
import { Comment } from 'react-loader-spinner';
import { errorStr } from "../../utils/constants/errorStrings";
import arrow from '../../assets/svg/arrow.svg';
import { MyContext } from "../../context/ContextApi";

const DynamicChart = () => {
  const {
    newQuery,
    setSessionUpdate,
    currentSession,
    setCurretSession,
    filterContent,
    personaValueList,
    setCurrentFaqVal,
    faqInputValue,
  } = useContext(MyContext);
  const location = useLocation();
  const currentPath = location.pathname.substring(1);
  const divRef = useRef(null);
  const [generating, setGenerating] = useState(false);
  const [sessionName, setSessionName] = useState(uuid());
  const [chartData, setChartData] = useState(null);
  const [chatsessionId, setchatSessionId] = useState("");
  const [inputValue, setInputValue] = useState('');
  const [data, setData] = useState([]);
  const [sessionModal, setSessionModal] = useState(false);
  const [sessionslist, setSessionList] = useState([]);

  let userEmail = sessionStorage.getItem("email");

  const [updation, setUpdation] = useState(true);

  useEffect(() => {
    setCurretSession("");
    setCurrentFaqVal({
      currentPath: "",
      currentColl: "",
    })
  }, [currentPath]);

  useEffect(() => {
      setInputValue(faqInputValue);
  }, [faqInputValue]);

  useEffect(() => {
    if (currentPath === "textchartquery") {
      setData([]);
      setSessionName(uuid());
      setUpdation(true);
      setCurretSession("");
    }
  }, [newQuery]);

  const handleGenerateChart = async () => {
    if (personaValueList) {
      console.log("value", personaValueList)
      if (inputValue !== "") {
        setInputValue("");
        setGenerating(true);    
        try {
          let persona = personaValueList.label; // Extracting label from personaValueList for persona
          let personaTables = personaValueList.value;
            
            let payload={
              user_query: inputValue,
              persona_tables: [personaTables],
              persona: persona,
              email_id: userEmail,
              session_id: sessionName
            }
            let apiRes = await getTexttoDashboard(payload);
          
            if (apiRes && apiRes.data) {
              setChartData(apiRes);

              // Make the chart result API call to store the response in the database
              let storePayload = {
                email_id: userEmail,
                user_query: inputValue,
                persona: persona,
                session_id: sessionName,
                full_data: apiRes?.data,
                layout: apiRes?.layout
              };

             await updateChartResponseInHistory(storePayload);
              
              setData((e)=>[...e,{
                user: inputValue,
                chartData: apiRes?.data,
                layout: apiRes?.layout,
              }
              ]);

              if (updation === true) {
                setSessionUpdate((current) => !current);
                setUpdation(false);
              }

              if (currentSession === "") {
                setCurretSession(sessionName);
              }
              setGenerating(false);
            } else {
              if (!apiRes) {
                setGenerating(false);
                toast.error("Apologies, your request is too long. Please try asking a shorter question or rephrase your input.");
              } else if (!apiRes.data) {
                setGenerating(false);
                toast.error("Apologies, your request is too long. Please try asking a shorter question or rephrase your input." );
              }
            }
        } catch (err) {
          setGenerating(false); 
          console.log(err);
        }
      } 
    } else {
      toast.error(errorStr.selectPersona);
    }
  };

  const cancelModal = () => {
    setSessionModal(false);
    setchatSessionId("");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setSessionModal(false);
  };

  return (
    <div className="mainContent" ref={divRef}>
     {data?.length === 0 ? (
       <ChatBot userName={filterContent?.userName} />
        
      ) : (
        <div className="prompt">
          {data?.map((e, i) => (
            <div key={i}>
              <div className="questions">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="29.178"
                  height="29.179"
                  viewBox="0 0 33.178 33.179"
                >
                  <g id="user" transform="translate(0 0)">
                    <path
                      id="Path_12221"
                      data-name="Path 12221"
                      d="M15.181,29l-4.95,2.7a4.448,4.448,0,0,0-.794.565,16.579,16.579,0,0,0,21.3.055,4.391,4.391,0,0,0-.873-.585l-5.3-2.65a2.021,2.021,0,0,1-1.117-1.808V25.2a8.005,8.005,0,0,0,.5-.645,12.2,12.2,0,0,0,1.648-3.32,1.665,1.665,0,0,0,1.181-1.584v-2.22a1.657,1.657,0,0,0-.555-1.23V12.995S26.883,8,20.12,8s-6.1,4.994-6.1,4.994V16.2a1.655,1.655,0,0,0-.555,1.23v2.22a1.664,1.664,0,0,0,.768,1.4A11.016,11.016,0,0,0,16.236,25.2v2.028A2.023,2.023,0,0,1,15.181,29Z"
                      transform="translate(-3.529 -2.992)"
                      fill="#e7eced"
                    />
                    <g
                      id="Group_1134"
                      data-name="Group 1134"
                      transform="translate(0 0)"
                    >
                      <path
                        id="Path_12222"
                        data-name="Path 12222"
                        d="M16.873,0A16.579,16.579,0,0,0,5.915,29.273a4.408,4.408,0,0,1,.787-.56l4.95-2.7a2.022,2.022,0,0,0,1.054-1.775V22.21A11.006,11.006,0,0,1,10.7,18.058a1.665,1.665,0,0,1-.768-1.4v-2.22a1.657,1.657,0,0,1,.555-1.23V10s-.659-4.994,6.1-4.994S22.693,10,22.693,10v3.209a1.655,1.655,0,0,1,.555,1.23v2.22a1.665,1.665,0,0,1-1.181,1.584,12.2,12.2,0,0,1-1.648,3.32,8.005,8.005,0,0,1-.5.645v2.08A2.021,2.021,0,0,0,21.035,26.1l5.3,2.65a4.414,4.414,0,0,1,.87.583A16.586,16.586,0,0,0,16.873,0Z"
                        transform="translate(0 0)"
                        fill="#62b5b4"
                      />
                    </g>
                  </g>
                </svg>
                &ensp;&ensp;
                <div>{e.user}</div>
              </div>
              <div className="answers">
                <div className="ml-1 robot">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="29.182"
                    height="29.179"
                    viewBox="0 0 33.182 33.179"
                  >
                    <g id="bot" transform="translate(0 -0.019)">
                      <g id="Page-1_16_" transform="translate(0 0.019)">
                        <g id="_x30_17---Chat-Bot" transform="translate(0 0)">
                          <path
                            id="Shape_96_"
                            d="M232.268,284.214a1.716,1.716,0,0,0,1.716-1.716h-3.432A1.716,1.716,0,0,0,232.268,284.214Z"
                            transform="translate(-215.676 -264.192)"
                            fill="#4c85f7"
                          />
                          <circle
                            id="Oval_29_"
                            cx="0.572"
                            cy="0.572"
                            r="0.572"
                            transform="translate(21.741 14.873)"
                            fill="#4c85f7"
                          />
                          <path
                            id="Shape_95_"
                            d="M128.378,167.739h-6.865a5.721,5.721,0,1,0,0,11.441h6.865a5.721,5.721,0,1,0,0-11.441Zm-9.153,6.293a1.716,1.716,0,1,1,1.716-1.716A1.716,1.716,0,0,1,119.225,174.032Zm5.721,4a2.86,2.86,0,0,1-2.86-2.86,1.148,1.148,0,0,1,1.144-1.144h3.432a1.148,1.148,0,0,1,1.144,1.144A2.86,2.86,0,0,1,124.946,178.036Zm5.721-4a1.716,1.716,0,1,1,1.716-1.716A1.716,1.716,0,0,1,130.667,174.032Z"
                            transform="translate(-108.354 -156.87)"
                            fill="#4c85f7"
                          />
                          <path
                            id="Shape_94_"
                            d="M164.834,408.372v3.089a16.389,16.389,0,0,1-6.55,1.482c-.1.006-.212.006-.315.006h-.023a16.582,16.582,0,0,1-6.842-1.487v-3.089a2.288,2.288,0,0,1,2.288-2.288h1.716v2.288a2.86,2.86,0,0,0,2.86,2.86,2.505,2.505,0,0,0,.292-.017,2.856,2.856,0,0,0,2.569-2.843v-2.288h1.716A2.288,2.288,0,0,1,164.834,408.372Z"
                            transform="translate(-141.377 -379.769)"
                            fill="#4c85f7"
                          />
                          <circle
                            id="Oval_28_"
                            cx="0.572"
                            cy="0.572"
                            r="0.572"
                            transform="translate(10.299 14.873)"
                            fill="#4c85f7"
                          />
                          <path
                            id="Shape_93_"
                            d="M230.552,408.373a1.716,1.716,0,1,0,3.432,0v-2.288h-3.432Z"
                            transform="translate(-215.676 -379.77)"
                            fill="#4c85f7"
                          />
                          <path
                            id="Shape_92_"
                            d="M34.182,16.609A16.586,16.586,0,0,1,25.6,31.139V28.622a3.432,3.432,0,0,0-2.094-3.158c4.382-1.5,6.67-4.908,6.67-10.1a13.988,13.988,0,0,0-1.144-5.623V6.312a.572.572,0,0,0-1.144,0V7.667a12.257,12.257,0,0,0-10.3-5.36,12.258,12.258,0,0,0-10.3,5.36V6.312a.572.572,0,0,0-.572-.572.585.585,0,0,0-.475.252.558.558,0,0,0-.1.32V9.738a13.987,13.987,0,0,0-1.144,5.623c0,5.194,2.288,8.6,6.67,10.1a3.432,3.432,0,0,0-2.094,3.158v2.517a16.59,16.59,0,1,1,24.6-14.53Z"
                            transform="translate(-1 -0.019)"
                            fill="#4c85f7"
                          />
                          <path
                            id="Shape_91_"
                            d="M91.924,52.981c-6.521,0-11.441,5.12-11.441,11.91,0,6.522,3.85,9.828,11.441,9.828s11.441-3.307,11.441-9.828C103.366,58.1,98.446,52.981,91.924,52.981Zm-.572,2.288H92.5a.572.572,0,1,1,0,1.144H91.352a.572.572,0,1,1,0-1.144Zm-1.144,1.716h3.432a.572.572,0,1,1,0,1.144H90.208a.572.572,0,0,1,0-1.144ZM95.357,73H88.492a6.865,6.865,0,0,1,0-13.73h6.865a6.865,6.865,0,0,1,0,13.73Z"
                            transform="translate(-75.332 -49.549)"
                            fill="#4c85f7"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                &ensp;
                <div className="sentences">
                <Plot
                  data={e.chartData}
                  layout={e.layout}
                  config={{
                    ...e.config,
                    displaylogo: false, // Set displaylogo to false to hide the Plotly logo
                    modeBarButtonsToRemove: ['lasso2d', 'select2d'], // Remove Lasso Select and Box Select buttons
                  }}
                /> 
                </div> 
              </div>
            </div>
          ))}
        </div>
       )} 
       {generating && (
        <div
          style={{
            display: "flex",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
            left: "40%",
            top: "82%",
            zIndex: 1000,
            border: "1px solid #ccc",
            backgroundColor: "whitesmoke",
            borderRadius: "5px",
            minWidth: "25px",
            padding: "10px",
          }}
        >
          Generating...{" "}
          <Comment
            visible={true}
            height="30"
            width="30"
            ariaLabel="comment-loading"
            wrapperStyle={{}}
            wrapperClass="comment-wrapper"
            color="white"
            backgroundColor="#4C85F7"
          />
        </div>
        )}

         <div className="query">
         <div className="input-container">
          <input
            type="text"
            className="input-field1"
            style={{ paddingLeft: '1%' }}
            placeholder="Type your query here"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleGenerateChart();
              }
            }}
            autoFocus
          />
          <span
            className="icon1"
            style={{
              cursor: inputValue !== "" ? "pointer" : "not-allowed",
            }}
            onClick={handleGenerateChart}
          >
            <img src={arrow} alt="bot" />
          </span>
        </div>
      </div> 
      <div>
        {" "}
        <Modal
          show={sessionModal}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          keyboard={false}
          centered
        >
          {" "}
          <form onSubmit={handleSubmit}>
            <Modal.Header closeButton={true}>
              <Modal.Title id="contained-modal-title-vcenter">
                Session Name
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <input
                type="text"
                className="w-100"
                name="sessionId"
                style={{ height: "38px", padding: "10px" }}
                placeholder="Session Name"
                onChange={(e) => setchatSessionId(e.target.value)}
                required
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={cancelModal}>
                Cancel
              </Button>
              <Link
                to={chatsessionId !== "" && `/textchartquery/${chatsessionId}`}
                state={{ chatsessionId, sessionslist, inputValue }}
              >
                <button className="btn btn-success">Save</button>
              </Link>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    </div>
  );
};

export default DynamicChart;