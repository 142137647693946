import React, { useEffect, useState, useRef, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import "./index.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { toast } from "react-toastify";
import uuid from "react-uuid";

import {
  audioGenerate,
  dataAudioHistory,
  feedBack,
  promptGenerate,
  sendEmail,
  sendDetailInWhatsApp,
  generateAudio,
} from "../../api/Postaction";
import { MyContext } from "../../context/ContextApi";

import {
  ChatBot,
  CommonFeedback,
  CommonGenerateBtn,
  CommonPromChat,
  CommonSendMail,
  CommonShareWhatsApp,
  SearchInputArrowBtn,
} from "../../common";
import { errorStr } from "../../utils/constants/errorStrings";
import { commonStr } from "../../utils/constants/commonStrings";
import { showToast } from "../../utils/helpers/helpers";

const NewChat = () => {
  const {
    newQuery,
    setSessionUpdate,
    currentSession,
    setCurretSession,
    filterContent,
    dropDownPersona,
    sessionPersonaVal,
    faqInputValue,
    setCurrentFaqVal,
  } = useContext(MyContext);
  const location = useLocation();
  const currentPath = location.pathname.substring(1);
  const divRef = useRef(null);

  const [RejectModal, setRejectModal] = useState(false);
  const [RejectAnswer, setRejectAnswer] = useState();
  const [sessionName, setSessionName] = useState(uuid());
  const [shareModal, setShareModal] = useState(false);
  const [numberModal, setNumberModal] = useState(false);
  const [shareDetails, setShareDetails] = useState();
  const [sendNumber, setSendNumber] = useState();
  const [generating, setGenerating] = useState(false);
  const [chatsessionId, setchatSessionId] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [data, setData] = useState([]);
  const [audio, setAudio] = useState([]);
  const [sessionModal, setSessionModal] = useState(false);
  const [sessionslist, setSessionList] = useState([]);

  let userEmail = sessionStorage.getItem("email");

  const [updation, setUpdation] = useState(true);
  const [feedbackInput, setFeedbackInput] = useState("");
  const [currentIdx, setCurrentIdx] = useState("");
  const [audioData, setAudioData] = useState(null);

  const [formData, setFormData] = useState({
    notes: "",
    emails: "",
  });

  const [formNumberData, setFormNumberData] = useState({
    number: "",
    text: "",
  });
  
  //input set Faq Value
  useEffect(() => {
    setInputValue(faqInputValue);
  }, [faqInputValue]);

  useEffect(() => {
    setData([]);
    setAudio([]);
    setSessionName(uuid());
    setUpdation(true);
    setCurretSession("");
  }, [sessionPersonaVal]);

  useEffect(() => {
    setCurretSession("");
    setCurrentFaqVal({
      currentPath: "",
      currentColl: "",
    });
  }, [currentPath]);

  useEffect(() => {
    if (currentPath === "newquery") {
      setData([]);
      setAudio([]);
      setSessionName(uuid());
      setUpdation(true);
      setCurretSession("");
    }
  }, [newQuery]);

  useEffect(() => {
    if (shareDetails !== undefined) {
      setFormData({
        notes: "",
        emails: "",
      });
      setShareModal(true);
    }
  }, [shareDetails]);

  useEffect(() => {
    if (sendNumber !== undefined) {
      setFormNumberData({
        number: "",
        text: "",
      });
      setNumberModal(true);
    }
  }, [sendNumber]);

  const closeShareModal = () => {
    setShareModal(false);
    setShareDetails();
  };

  const closePhoneNumberModal = () => {
    setNumberModal(false);
    setSendNumber();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      generatePrompt();
    }
  };

  const generatePrompt = async () => {
    if (dropDownPersona) {
      if (inputValue !== "") {
        setInputValue("");
        setGenerating(true);
        try {
          let a = await promptGenerate(
            userEmail,
            sessionName,
            inputValue,
            dropDownPersona,
            sessionPersonaVal
          );

          let b;
          if (a !== undefined) {
            if (a?.status_code === 200) {
              setData((prev) => [
                ...prev,
                {
                  user: inputValue,
                  AI: a.summary,
                  thumbsUp: false,
                  thumbsDown: false,
                  Message_Id: "",
                },
              ]);
              setGenerating(false);
              b = await audioGenerate(a.summary);
              if (updation === true) {
                setSessionUpdate((current) => !current);
                setUpdation(false);
              }
            } else {
              setGenerating(false);
              toast.error("Error in generating prompt");
            }
            let value = {
              email_id: userEmail,
              session_id: sessionName,
              s3_url: b,
              persona_name: sessionPersonaVal,
            };
            let id = await dataAudioHistory(value);
            if (currentSession === "") {
              setCurretSession(sessionName);
            }
            setAudio((prev) => [
              ...prev,
              {
                audio: b,
                Message_Id: id,
              },
            ]);
          }
        } catch (err) {
          setGenerating(false);
          console.log(err);
        } finally {
          setGenerating(false);
        }
      }
    } else {
      toast.error(errorStr.selectPersona);
    }
  };

  const handleThumbsUpClick = async (index) => {
    setRejectAnswer();
    const newThumbsStates = [...data];
    newThumbsStates[index].thumbsUp = !newThumbsStates[index].thumbsUp;
    newThumbsStates[index].thumbsDown = false; // Reset thumbs down
    setData(newThumbsStates);
    let a = data[index];

    let body = {
      message_id: audio[index].Message_Id,
      question: a.user,
      response: a.AI,
      feedback: "thumbs-up",
      // email_id: userEmail,
      // session_id: sessionName,
    };

    let feedbackStatus = await feedBack(body);
    if (feedbackStatus?.status === 200) {
      showToast(feedbackStatus?.data?.message, 1);
    }
  };

  const handleThumbsDownClick = (e, index) => {
    setFeedbackInput("");
    let msgId = audio[index].Message_Id;
    let mergeData = {
      ...e,
      Message_Id: msgId,
    };
    setRejectModal(true);
    setRejectAnswer(mergeData);
    const newThumbsStates = [...data];
    newThumbsStates[index].thumbsDown = !newThumbsStates[index].thumbsDown;
    newThumbsStates[index].thumbsUp = false; // Reset thumbs up
    setData(newThumbsStates);
  };
  const closeRejectModal = () => {
    let a = data.filter((e) => e.user === RejectAnswer.user)[0];
    const indexOfObjectToUpdate = data.findIndex(
      (item) => item.user === a.user
    );
    if (indexOfObjectToUpdate !== -1) {
      data[indexOfObjectToUpdate].thumbsUp = false;
      data[indexOfObjectToUpdate].thumbsDown = false;
    }
    setRejectAnswer();
    setRejectModal(false);
  };
  const submitRejectModal = async (e) => {
    e.preventDefault();
    let body = {
      message_id: RejectAnswer.Message_Id,
      question: RejectAnswer.user,
      response: RejectAnswer.AI,
      feedback: "thumbs-down",
      comment: feedbackInput,
      // email_id: userEmail,
      // session_id: sessionName,
    };
    setRejectAnswer();
    setRejectModal(false);
    let feedbackStatus = await feedBack(body);
    if (feedbackStatus?.status === 200) {
      showToast(feedbackStatus?.data?.message, 1);
      setFeedbackInput("");
    }
  };

  const cancelModal = () => {
    setSessionModal(false);
    setchatSessionId("");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setSessionModal(false);
  };
  const share = async (e) => {
    setShareModal(false);
    e.preventDefault();
    let body = {
      Note: formData.notes,
      recipients: [formData.emails],
      insight: shareDetails.AI,
      file_path: audio[currentIdx].audio,
      sender: userEmail,
    };
    await sendEmail(body);
    setShareDetails();
  };

  const shareToWhatsApp = async (e) => {
    setNumberModal(false);
    e.preventDefault();
    let body = {
      recipient_id: formNumberData.number,
      text: sendNumber.user + "\n" + sendNumber.AI,
    };
    await sendDetailInWhatsApp(body);
    setSendNumber();
  };

  //handleToChangeTextArea
  const handleChangeTextArea = (e) => {
    setFeedbackInput(e.target.value);
  };

  //handleInputChange
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleInputNumberChange = (e) => {
    const { name, value } = e.target;
    setFormNumberData({
      ...formNumberData,
      [name]: value,
    });
  };

  //handleRecordData
  const handleRecordingData = (data) => {
    if (data) {
      setAudioData(data);
    }
  };

  const handleToStopAudio = async () => {
    if (dropDownPersona) {
      if (audioData) {
        setAudioData("");
        setGenerating(true);

        try {
          let convertedData = await generateAudio(audioData);

          let audioResponse = await promptGenerate(
            userEmail,
            sessionName,
            convertedData,
            // dropDownPersona,
            sessionPersonaVal
          );

          let sectionData;
          if (audioResponse !== undefined) {
            if (audioResponse?.status === 200) {
              setData((prev) => [
                ...prev,
                {
                  user: convertedData,
                  AI: audioResponse,
                  thumbsUp: false,
                  thumbsDown: false,
                  Message_Id: "",
                },
              ]);
              setGenerating(false);
              sectionData = await audioGenerate(audioResponse);
              if (updation === true) {
                setSessionUpdate((current) => !current);
                setUpdation(false);
              }
            } else {
              setGenerating(false);
              toast.error("Error in generating prompt");
              sectionData = "";
            }
            let value = {
              email_id: userEmail,
              session_id: sessionName,
              s3_url: sectionData,
              persona_name: sessionPersonaVal,
            };
            let id = await dataAudioHistory(value);
            if (currentSession === "") {
              setCurretSession(sessionName);
            }
            setAudio((prev) => [
              ...prev,
              {
                audio: sectionData,
                Message_Id: id,
              },
            ]);
          }
        } catch (err) {
          setGenerating(false);
          console.log(err);
        }
      }
    } else {
      toast.error(errorStr.selectPersona);
    }
  };

  useEffect(() => {
    if (audioData !== null) {
      handleToStopAudio();
    }
  }, [audioData]);

  const handleMergeEvaluageData = (evaluateData, id) => {
    let duplicateData = [...data];
    let mergeData = duplicateData.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          evaluate: evaluateData,
        };
      }
      return item;
    });
    setData(mergeData);
  };

  return (
    <div className="mainContent" ref={divRef}>
      {data?.length === 0 ? (
        <ChatBot userName={filterContent?.userName} />
      ) : (
        <div className="prompt">
          {data &&
            data?.length > 0 &&
            data?.map((e, i) => (
              <CommonPromChat
                key={i + 1}
                indexValue={i}
                user={e.user}
                AI={e.AI}
                audio={audio}
                thumbsUp={data[i].thumbsUp}
                thumbsDown={data[i].thumbsDown}
                onThumbsUpClick={() => handleThumbsUpClick(i)}
                onThumbsDownClick={() => handleThumbsDownClick(e, i)}
                onShareClick={() => {
                  setShareDetails(e);
                  setCurrentIdx(i);
                }}
                onShareNumberClick={() => {
                  setSendNumber(e);
                }}
                audioVisble={true}
                updateEvaluateData={(data) =>
                  handleMergeEvaluageData(data, e?.id)
                }
                listData={e}
                hidePayload={3}
              />
            ))}
        </div>
      )}
      {generating && <CommonGenerateBtn />}

      <SearchInputArrowBtn
        value={inputValue}
        placeholder={commonStr.typeYourQuery}
        onKeyPress={handleKeyPress}
        onChange={(e) => setInputValue(e.target.value)}
        inputValue={inputValue}
        onClick={generatePrompt}
        handleRecordingData={handleRecordingData}
        handleToStopAudio={handleToStopAudio}
      />

      <div>
        {sessionModal && (
          <Modal
            show={sessionModal}
            // onHide={() => setModalShow(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            keyboard={false}
            centered
          >
            {" "}
            <form onSubmit={handleSubmit}>
              <Modal.Header closeButton={true}>
                <Modal.Title id="contained-modal-title-vcenter">
                  Session Name
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <input
                  type="text"
                  className="w-100"
                  name="sessionId"
                  style={{ height: "38px", padding: "10px" }}
                  placeholder="Session Name"
                  onChange={(e) => setchatSessionId(e.target.value)}
                  required
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={cancelModal}>
                  Cancel
                </Button>
                <Link
                  to={chatsessionId !== "" && `/newquery/${chatsessionId}`}
                  state={{ chatsessionId, sessionslist, inputValue }}
                >
                  <button className="btn btn-success">Save</button>
                </Link>
              </Modal.Footer>
            </form>
          </Modal>
        )}

        {shareModal && (
          <CommonSendMail
            show={shareModal}
            onSubmit={share}
            closeShareModal={closeShareModal}
            handleInputChange={handleInputChange}
            formData={formData}
          />
        )}
        {numberModal && (
          <CommonShareWhatsApp
            show={numberModal}
            onSubmit={shareToWhatsApp}
            closeShareModal={closePhoneNumberModal}
            handleInputChange={handleInputNumberChange}
            formData={formNumberData}
          />
        )}
      </div>
      {RejectModal && (
        <CommonFeedback
          show={RejectModal}
          onSubmit={submitRejectModal}
          onchangeTextArea={handleChangeTextArea}
          onchangeTextAreaValue={feedbackInput}
          closeRejectModal={closeRejectModal}
        />
      )}
    </div>
  );
};

export default NewChat;
