import React, { useState, useContext, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "../documentQuery/Document.css";
import {
  audioGenerate,
  documentAudioHistory,
  documentPrompt,
  feedBack,
  getModalType,
  sendEmail,
  sendDetailInWhatsApp,
  generateAudioForDoc,
} from "../../api/Postaction";
import { MyContext } from "../../context/ContextApi";
import uuid from "react-uuid";

import {
  getDocumentPayload,
  setModuleValue,
  showToast,
} from "../../utils/helpers/helpers";
import {
  ChatBot,
  CommonFeedback,
  CommonGenerateBtn,
  CommonPromChat,
  CommonSendMail,
  CommonShareWhatsApp,
  SearchInputArrowBtn,
  Sourcebar,
} from "../../common";
import { commonStr } from "../../utils/constants/commonStrings";
import { errorStr } from "../../utils/constants/errorStrings";

const Document1 = () => {
  const {
    allValues,
    currentSession,
    setCurretSession,
    newQuery,
    setSessionUpdate,
    filterContent,
    faqInputValue,
    setFaqInputValue,
    setCurrentFaqVal,
  } = useContext(MyContext);
  const location = useLocation();
  const currentPath = location.pathname.substring(1);
  const [shareModal, setShareModal] = useState(false);
  const [numberModal, setNumberModal] = useState(false);
  const [RejectModal, setRejectModal] = useState(false);
  const [RejectAnswer, setRejectAnswer] = useState();
  const [data, setData] = useState([]);
  const [sessionName, setSessionName] = useState(uuid());

  const [inputValue, setInputValue] = useState("");
  const [generating, setGenerating] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [sessionModal, setSessionModal] = useState(false);
  const [updation, setUpdation] = useState(true);
  const [shareDetails, setShareDetails] = useState();
  const [sendNumber, setSendNumber] = useState();
  let userEmail = sessionStorage.getItem("email");
  const [sourceData, setSourceData] = useState();
  const [audio, setAudio] = useState([]);
  const [feedbackInput, setFeedbackInput] = useState("");
  const [currentIdx, setCurrentIdx] = useState("");
  const [audioData, setAudioData] = useState(null);

  const [formData, setFormData] = useState({
    notes: "",
    emails: "",
  });

  const [formNumberData, setFormNumberData] = useState({
    number: "",
    text: "",
  });

  useEffect(() => {
    setCurretSession("");
    setCurrentFaqVal({
      currentPath: "",
      currentColl: "",
    });
  }, [currentPath]);

  const cancelModal = () => {
    setSessionModal(false);
    setSessionId("");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      generatePrompt();
    }
  };

  //input set Faq Value
  useEffect(() => {
    setInputValue(faqInputValue);
  }, [faqInputValue]);

  useEffect(() => {
    if (currentPath === "documentquery") {
      setData([]);
      setAudio([]);
      setSourceData([]);
      setSessionName(uuid());
      setUpdation(true);
      setCurretSession("");
    }
  }, [newQuery]);

  useEffect(() => {
    if (shareDetails !== undefined) {
      setFormData({
        notes: "",
        emails: "",
      });
      setShareModal(true);
    }
  }, [shareDetails]);

  useEffect(() => {
    if (sendNumber !== undefined) {
      setFormNumberData({
        number: "",
        text: "",
      });
      setNumberModal(true);
    }
  }, [sendNumber]);

  useEffect(() => {
    getModalType();
  }, []);

  const generatePrompt = async () => {
    if (allValues !== undefined) {
      if (inputValue === "") {
        showToast(errorStr.pleaseEnterQuery);
      } else {
        await setModuleValue();
        setInputValue("");
        setFaqInputValue("");
        setGenerating(true);
        let body = {
          string_to_search: inputValue,
          email_id: userEmail,
          session_id: sessionName,
          collection_name: allValues.collection,
          ...getDocumentPayload(true),
        };

        try {
          let a = await documentPrompt(body);
          let b;
          if (a[0] !== undefined) {
            setData((prev) => [
              ...prev,
              {
                id: data.length + 1,
                user: inputValue,
                AI: a[0],
                source: a[2],
                thumbsUp: false,
                thumbsDown: false,
              },
            ]);
            if (data.length === 0) {
              setSessionUpdate((current) => !current);
            }
            if (currentSession === "") {
              setCurretSession(sessionName);
            }

            b = await audioGenerate(a[0]);
            // }

            let value = {
              email_id: userEmail,
              session_id: sessionName,
              s3_url: b,
              collection: allValues?.collection,
            };
            let id = await documentAudioHistory(value);

            setAudio((prev) => [
              ...prev,
              {
                audio: b,
                Message_Id: id,
              },
            ]);

            setSourceData({
              id: data.length + 1,
              user: inputValue,
              AI: a[0],
              source: a[2],
              rating: "",
            });
          }

          setGenerating(false);
        } catch (err) {
          setGenerating(false);
        }
      }
    } else {
      showToast(errorStr.pleaseSelectCollection);
    }
  };

  const handleSubmit1 = (e) => {
    e.preventDefault();
    setSessionModal(false);
  };
  const handleThumbsUpClick = async (index) => {
    setRejectAnswer();
    const newThumbsStates = [...data];
    newThumbsStates[index].thumbsUp = !newThumbsStates[index].thumbsUp;
    newThumbsStates[index].thumbsDown = false; // Reset thumbs down
    setData(newThumbsStates);
    let a = data[index];
    let body = {
      message_id: audio[index].Message_Id,
      question: a.user,
      response: a.AI,
      feedback: "thumbs-up",
      email_id: userEmail,
      session_id: currentPath,
    };

    let feedbackStatus = await feedBack(body);
    if (feedbackStatus?.status === 200) {
      showToast(feedbackStatus?.data?.message, 1);
    }
  };

  const handleThumbsDownClick = (e, index) => {
    let msgId = audio[index].Message_Id;
    let mergeData = {
      ...e,
      Message_Id: msgId,
    };
    setRejectModal(true);
    setRejectAnswer(mergeData);
    setFeedbackInput("");

    const newThumbsStates = [...data];
    newThumbsStates[index].thumbsDown = !newThumbsStates[index].thumbsDown;
    newThumbsStates[index].thumbsUp = false; // Reset thumbs up
    setData(newThumbsStates);
  };
  const share = async (e) => {
    setShareModal(false);
    e.preventDefault();
    let body = {
      Note: formData.notes,
      recipients: [formData.emails],
      insight: shareDetails.AI,
      file_path: audio[currentIdx].audio,
      sender: userEmail,
    };
    await sendEmail(body);
    setShareDetails();
  };
  const closeShareModal = () => {
    setShareModal(false);
    setShareDetails();
  };

  const shareToWhatsApp = async (e) => {
    setNumberModal(false);
    e.preventDefault();
    let body = {
      recipient_id: formNumberData.number,
      text: sendNumber.user + "\n" + sendNumber.AI,
    };
    await sendDetailInWhatsApp(body);
    setSendNumber();
  };
  const closePhoneNumberModal = () => {
    setNumberModal(false);
    setSendNumber();
  };

  const closeRejectModal = () => {
    let a = data.filter((e) => e.user === RejectAnswer.user)[0];
    const indexOfObjectToUpdate = data.findIndex(
      (item) => item.user === a.user
    );
    if (indexOfObjectToUpdate !== -1) {
      data[indexOfObjectToUpdate].thumbsUp = false;
      data[indexOfObjectToUpdate].thumbsDown = false;
    }
    setRejectAnswer();
    setRejectModal(false);
  };

  const submitRejectModal = async (e) => {
    e.preventDefault();
    let body = {
      message_id: RejectAnswer.Message_Id,
      question: RejectAnswer.user,
      response: RejectAnswer.AI,
      feedback: "thumbs-down",
      comment: feedbackInput,
      email_id: userEmail,
      session_id: currentPath,
    };
    setRejectAnswer();
    setRejectModal(false);

    let feedbackStatus = await feedBack(body);
    if (feedbackStatus?.status === 200) {
      showToast(feedbackStatus?.data?.message, 1);
      setFeedbackInput("");
    }
  };

  //handleToChangeTextArea
  const handleChangeTextArea = (e) => {
    setFeedbackInput(e.target.value);
  };

  //handleInputChange
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleInputNumberChange = (e) => {
    const { name, value } = e.target;
    setFormNumberData({
      ...formNumberData,
      [name]: value,
    });
  };

  //handleRecordData
  const handleRecordingData = (data) => {
    if (data) {
      setAudioData(data);
    }
  };

  const handleToStopAudio = async () => {
    if (allValues !== undefined) {
      if (audioData) {
        setAudioData("");
        setGenerating(true);

        try {
          let convertedData = await generateAudioForDoc(audioData);

          let body = {
            string_to_search: convertedData,
            email_id: userEmail,
            session_id: sessionName,
            collection_name: allValues.collection,
            ...getDocumentPayload(true),
          };

          let audioRes = await documentPrompt(body);
          let sectionData;

          if (audioRes[0] !== undefined) {
            setData((prev) => [
              ...prev,
              {
                id: data.length + 1,
                user: convertedData,
                AI: audioRes[0],
                source: audioRes[2],
                thumbsUp: false,
                thumbsDown: false,
              },
            ]);

            sectionData = await audioGenerate(audioRes[0]);

            if (data.length === 0) {
              setSessionUpdate((current) => !current);
            }
            let value = {
              email_id: userEmail,
              session_id: sessionName,
              s3_url: sectionData,
              collection: allValues?.collection,
            };

            let id = await documentAudioHistory(value);
            if (currentSession === "") {
              setCurretSession(sessionName);
            }
            setAudio((prev) => [
              ...prev,
              {
                audio: sectionData,
                Message_Id: id,
              },
            ]);

            setSourceData({
              id: data.length + 1,
              user: inputValue,
              AI: audioRes[0],
              source: audioRes[2],
              rating: "",
            });
          }
          setGenerating(false);
        } catch (err) {
          showToast(errorStr.errorGeneratePrompt);
          setGenerating(false);
        }
      }
    } else {
      showToast(errorStr.pleaseSelectCollection);
    }
  };

  useEffect(() => {
    if (audioData !== null) {
      handleToStopAudio();
    }
  }, [audioData]);

  const handleMergeEvaluageData = (evaluateData, id) => {
    let duplicateData = [...data];
    let mergeData = duplicateData.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          evaluate: evaluateData,
        };
      }
      return item;
    });
    setData(mergeData);
  };

  return (
    <div className="d-flex justify-content-between postionRelative">
      <div className="mainContent w-100">
        {data.length === 0 ? (
          <ChatBot userName={filterContent?.userName} />
        ) : (
          <div className="prompt">
            {data &&
              data?.length > 0 &&
              data?.map((e, i) => (
                <CommonPromChat
                  indexValue={i}
                  key={i + 1}
                  user={e.user}
                  AI={e.AI}
                  audio={audio}
                  thumbsUp={data[i].thumbsUp}
                  thumbsDown={data[i].thumbsDown}
                  onThumbsUpClick={() => handleThumbsUpClick(i)}
                  onThumbsDownClick={() => handleThumbsDownClick(e, i)}
                  onShareClick={() => {
                    setShareDetails(e);
                    setCurrentIdx(i);
                  }}
                  onShareNumberClick={() => {
                    setSendNumber(e);
                  }}
                  isViewSource={e?.id !== sourceData?.id}
                  onClickViewSource={() => setSourceData(e)}
                  updateEvaluateData={(data) =>
                    handleMergeEvaluageData(data, e?.id)
                  }
                  listData={e}
                />
              ))}
          </div>
        )}
        {generating && <CommonGenerateBtn title={commonStr.searching} />}

        <SearchInputArrowBtn
          value={inputValue}
          placeholder={commonStr.typeYourQuery}
          onKeyPress={handleKeyPress}
          onChange={(e) => setInputValue(e.target.value)}
          inputValue={inputValue}
          onClick={generatePrompt}
          handleRecordingData={handleRecordingData}
          handleToStopAudio={handleToStopAudio}
        />

        <div>
          {" "}
          {sessionModal && (
            <Modal
              show={sessionModal}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              backdrop="static"
              keyboard={false}
              centered
            >
              {" "}
              <form onSubmit={handleSubmit1}>
                <Modal.Header closeButton={true}>
                  <Modal.Title id="contained-modal-title-vcenter">
                    Session Name
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <input
                    type="text"
                    className="w-100"
                    name="sessionId"
                    style={{ height: "38px", padding: "10px" }}
                    placeholder="Session Name"
                    onChange={(e) => setSessionId(e.target.value)}
                    required
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={cancelModal}>
                    Cancel
                  </Button>
                  <Link
                    to={`/documentquery/${sessionId}`}
                    state={{ sessionId, inputValue }}
                  >
                    <button className="btn btn-success">Save</button>
                  </Link>
                </Modal.Footer>
              </form>
            </Modal>
          )}
          {shareModal && (
            <CommonSendMail
              show={shareModal}
              onSubmit={share}
              closeShareModal={closeShareModal}
              handleInputChange={handleInputChange}
              formData={formData}
            />
          )}
          {numberModal && (
            <CommonShareWhatsApp
              show={numberModal}
              onSubmit={shareToWhatsApp}
              closeShareModal={closePhoneNumberModal}
              handleInputChange={handleInputNumberChange}
              formData={formNumberData}
            />
          )}
          {RejectModal && (
            <CommonFeedback
              show={RejectModal}
              onSubmit={submitRejectModal}
              onchangeTextArea={handleChangeTextArea}
              onchangeTextAreaValue={feedbackInput}
              closeRejectModal={closeRejectModal}
            />
          )}
        </div>
      </div>
      <Sourcebar data1={sourceData} />
    </div>
  );
};

export default Document1;
