import React, { useState, useContext, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "./ComDataDetective.css";
import {
  feedBack,
  getModalType,
  sendEmail,
  sendDetailInWhatsApp,
  dataDectectivePromptApi,
  comDectectivePromptApi,
} from "../../api/Postaction";
import { MyContext } from "../../context/ContextApi";
import uuid from "react-uuid";

import { showToast } from "../../utils/helpers/helpers";
import {
  ChatBot,
  CommonFeedback,
  CommonGenerateBtn,
  CommonPromChat,
  CommonSendMail,
  CommonShareWhatsApp,
  SearchInputArrowBtn,
} from "../../common";
import { commonStr } from "../../utils/constants/commonStrings";
import { errorStr } from "../../utils/constants/errorStrings";

const DataDetective = () => {
  const {
    setSessionUpdate,
    currentSession,
    setCurretSession,
    newQuery,
    filterContent,
    faqInputValue,
    comPersonaTab,
    comPersonaVal,
    setCurrentFaqVal,
  } = useContext(MyContext);
  const location = useLocation();
  const currentPath = location.pathname.substring(1);
  const [shareModal, setShareModal] = useState(false);
  const [numberModal, setNumberModal] = useState(false);
  const [RejectModal, setRejectModal] = useState(false);
  const [RejectAnswer, setRejectAnswer] = useState();
  const [data, setData] = useState([]);
  const [sessionName, setSessionName] = useState(uuid());

  const [inputValue, setInputValue] = useState("");
  const [generating, setGenerating] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [sessionModal, setSessionModal] = useState(false);
  const [updation, setUpdation] = useState(true);
  const [shareDetails, setShareDetails] = useState();
  const [sendNumber, setSendNumber] = useState();
  let userEmail = sessionStorage.getItem("email");
  const [audio, setAudio] = useState([]);
  const [feedbackInput, setFeedbackInput] = useState("");
  const [currentIdx, setCurrentIdx] = useState("");

  const [formData, setFormData] = useState({
    notes: "",
    emails: "",
  });

  const [formNumberData, setFormNumberData] = useState({
    number: "",
    text: "",
  });

  useEffect(() => {
    setCurretSession("");
    setCurrentFaqVal({
      currentPath: "",
      currentColl: "",
    })
  }, [currentPath]);

  //input set Faq Value
  useEffect(() => {
    // if (faqInputValue) {
      setInputValue(faqInputValue);
    // }
  }, [faqInputValue]);

  const cancelModal = () => {
    setSessionModal(false);
    setSessionId("");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      generatePrompt();
    }
  };
  useEffect(() => {
    if (currentPath === "comdataDetective") {
      setData([]);
      setSessionName(uuid());
      setUpdation(true);
      setCurretSession("");
    }
  }, [newQuery, comPersonaVal]);

  useEffect(() => {
    if (currentSession === "") {
      setData([]);
      setSessionName(uuid());
      setUpdation(true);
      setCurretSession("");
    }
  }, [currentSession]);

  useEffect(() => {
    if (shareDetails !== undefined) {
      setFormData({
        notes: "",
        emails: "",
      });
      setShareModal(true);
    }
  }, [shareDetails]);

  useEffect(() => {
    if (sendNumber !== undefined) {
      setFormNumberData({
        number: "",
        text: "",
      });
      setNumberModal(true);
    }
  }, [sendNumber]);

  useEffect(() => {
    getModalType();
  }, []);

  const generatePrompt = async () => {
    if (inputValue === "") {
      showToast(errorStr.pleaseEnterQuery);
    } else {
      setInputValue("");
      setGenerating(true);
      let body = {
        question: inputValue,
        email_id: userEmail,
        session_id: sessionName,
        persona_name: comPersonaVal,
        persona_tables: comPersonaTab,
      };

      try {
        let a = await comDectectivePromptApi(body);
        if (a !== undefined) {
          setData((prev) => [
            ...prev,
            {
              id: data.length + 1,
              user: inputValue,
              AI: a?.output,
              thumbsUp: false,
              thumbsDown: false,
              steps: a?.intermediate_steps,
            },
          ]);
        }
        if (data.length === 0) {
          setSessionUpdate((current) => !current);
        }
        if (currentSession === "") {
          setCurretSession(sessionName);
        }
        setGenerating(false);
      } catch (err) {
        setGenerating(false);
      }
    }
  };

  const handleSubmit1 = (e) => {
    e.preventDefault();
    setSessionModal(false);
  };
  const handleThumbsUpClick = async (index) => {
    setRejectAnswer();
    const newThumbsStates = [...data];
    newThumbsStates[index].thumbsUp = !newThumbsStates[index].thumbsUp;
    newThumbsStates[index].thumbsDown = false; // Reset thumbs down
    setData(newThumbsStates);
    let a = data[index];
    let body = {
      message_id: uuid(),
      question: a.user,
      response: a.AI,
      feedback: "thumbs-up",
      email_id: userEmail,
      session_id: currentPath,
    };

    let feedbackStatus = await feedBack(body);
    if (feedbackStatus?.status === 200) {
      showToast(feedbackStatus?.data?.message, 1);
    }
  };

  const handleThumbsDownClick = (e, index) => {
    let msgId = uuid();
    let mergeData = {
      ...e,
      Message_Id: msgId,
    };
    setRejectModal(true);
    setRejectAnswer(mergeData);
    setFeedbackInput("");

    const newThumbsStates = [...data];
    newThumbsStates[index].thumbsDown = !newThumbsStates[index].thumbsDown;
    newThumbsStates[index].thumbsUp = false; // Reset thumbs up
    setData(newThumbsStates);
  };
  const share = async (e) => {
    setShareModal(false);
    e.preventDefault();
    let body = {
      Note: formData.notes,
      recipients: [formData.emails],
      insight: shareDetails.AI,
      file_path: audio[currentIdx].audio,
      sender: userEmail,
    };
    await sendEmail(body);
    setShareDetails();
  };
  const closeShareModal = () => {
    setShareModal(false);
    setShareDetails();
  };

  const shareToWhatsApp = async (e) => {
    setNumberModal(false);
    e.preventDefault();
    let body = {
      recipient_id: formNumberData.number,
      text: sendNumber.user + "\n" + sendNumber.AI,
    };
    await sendDetailInWhatsApp(body);
    setSendNumber();
  };
  const closePhoneNumberModal = () => {
    setNumberModal(false);
    setSendNumber();
  };

  const closeRejectModal = () => {
    let a = data.filter((e) => e.user === RejectAnswer.user)[0];
    const indexOfObjectToUpdate = data.findIndex(
      (item) => item.user === a.user
    );
    if (indexOfObjectToUpdate !== -1) {
      data[indexOfObjectToUpdate].thumbsUp = false;
      data[indexOfObjectToUpdate].thumbsDown = false;
    }
    setRejectAnswer();
    setRejectModal(false);
  };

  const submitRejectModal = async (e) => {
    e.preventDefault();
    let body = {
      message_id: RejectAnswer.Message_Id,
      question: RejectAnswer.user,
      response: RejectAnswer.AI,
      feedback: "thumbs-down",
      comment: feedbackInput,
      email_id: userEmail,
      session_id: currentPath,
    };
    setRejectAnswer();
    setRejectModal(false);

    let feedbackStatus = await feedBack(body);
    if (feedbackStatus?.status === 200) {
      showToast(feedbackStatus?.data?.message, 1);
      setFeedbackInput("");
    }
  };

  //handleToChangeTextArea
  const handleChangeTextArea = (e) => {
    setFeedbackInput(e.target.value);
  };

  //handleInputChange
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleInputNumberChange = (e) => {
    const { name, value } = e.target;
    setFormNumberData({
      ...formNumberData,
      [name]: value,
    });
  };

  return (
    <div className="d-flex justify-content-between postionRelative">
      <div className="mainContent w-100">
        {data?.length === 0 ? (
          <ChatBot userName={filterContent?.userName} />
        ) : (
          <div className="prompt">
            {data &&
              data?.length > 0 &&
              data?.map((e, i) => (
                <CommonPromChat
                  indexValue={i}
                  key={i + 1}
                  user={e.user}
                  AI={e.AI}
                  thumbsUp={data[i].thumbsUp}
                  thumbsDown={data[i].thumbsDown}
                  onThumbsUpClick={() => handleThumbsUpClick(i)}
                  onThumbsDownClick={() => handleThumbsDownClick(e, i)}
                  onShareClick={() => {
                    setShareDetails(e);
                    setCurrentIdx(i);
                  }}
                  onShareNumberClick={() => {
                    setSendNumber(e);
                  }}
                  audioVisble={false}
                  hidelike={false}
                  isDashboardSplit
                  isShowDetective
                  listData={e}
                  hideInfoDetective
                />
              ))}
          </div>
        )}
        {generating && <CommonGenerateBtn title={commonStr.thinking} />}

        <SearchInputArrowBtn
          value={inputValue}
          placeholder={commonStr.typeYourQuery}
          onKeyPress={handleKeyPress}
          onChange={(e) => setInputValue(e.target.value)}
          inputValue={inputValue}
          onClick={generatePrompt}
          audioHide={false}
        />

        <div>
          {" "}
          {sessionModal && (
            <Modal
              show={sessionModal}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              backdrop="static"
              keyboard={false}
              centered
            >
              {" "}
              <form onSubmit={handleSubmit1}>
                <Modal.Header closeButton={true}>
                  <Modal.Title id="contained-modal-title-vcenter">
                    Session Name
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <input
                    type="text"
                    className="w-100"
                    name="sessionId"
                    style={{ height: "38px", padding: "10px" }}
                    placeholder="Session Name"
                    onChange={(e) => setSessionId(e.target.value)}
                    required
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={cancelModal}>
                    Cancel
                  </Button>
                  <Link
                    to={`/dataDetective/${sessionId}`}
                    state={{ sessionId, inputValue }}
                  >
                    <button className="btn btn-success">Save</button>
                  </Link>
                </Modal.Footer>
              </form>
            </Modal>
          )}
          {shareModal && (
            <CommonSendMail
              show={shareModal}
              onSubmit={share}
              closeShareModal={closeShareModal}
              handleInputChange={handleInputChange}
              formData={formData}
            />
          )}
          {numberModal && (
            <CommonShareWhatsApp
              show={numberModal}
              onSubmit={shareToWhatsApp}
              closeShareModal={closePhoneNumberModal}
              handleInputChange={handleInputNumberChange}
              formData={formNumberData}
            />
          )}
          {RejectModal && (
            <CommonFeedback
              show={RejectModal}
              onSubmit={submitRejectModal}
              onchangeTextArea={handleChangeTextArea}
              onchangeTextAreaValue={feedbackInput}
              closeRejectModal={closeRejectModal}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DataDetective;
