import React, { useState } from "react";
import { Table, Pagination } from "rsuite";
import "./mlrReview.css";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import { CommonLoader } from "../../common";

const PersonalIdentification = ({ orangeFlag, redFlag, currentPage }) => {
  const { Column, HeaderCell, Cell } = Table;

  const filteredOrangeFlag = orangeFlag.filter(item => item["Page No"] === currentPage);
  const filteredRedFlag = redFlag.filter(item => item["Page No"] === currentPage);

  const [orangePage, setOrangePage] = useState(1);
  const [redPage, setRedPage] = useState(1);
  const [redPageSize, setRedPageSize] = useState(10);
  const [orangePageSize, setOrangePageSize] = useState(10);

  // Function to convert flag data into table format
  const convertToTableFormat = (flagData) => {
    if (!flagData) return []; // Check if flagData is undefined
    return flagData.map((flag, index) => {
      return {
        "S.No": index + 1,
        ...flag,
      };
    });
  };

  const orangeTableData = convertToTableFormat(orangeFlag || []);
  const redTableData = convertToTableFormat(redFlag || []);

  // Function to handle orange flag page change
  const handleOrangePageChange = (page) => {
    setOrangePage(page);
  };

  // Function to handle red flag page change
  const handleRedPageChange = (page) => {
    setRedPage(page);
  };

  // Function to handle changing the number of items per page in red flag
  const handleRedLimitChange = (limit) => {
    setRedPageSize(limit);
    setRedPage(1);
  };

  // Function to handle changing the number of items per page in orange flag
  const handleOrangeLimitChange = (limit) => {
    setOrangePageSize(limit);
    setOrangePage(1);
  };

  const paginateData = (data, page, pageSize) => {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return data.slice(startIndex, endIndex);
  };

  const paginatedRedTableData = paginateData(
    redTableData,
    redPage,
    redPageSize
  );
  const paginatedOrangeTableData = paginateData(
    orangeTableData,
    orangePage,
    orangePageSize
  );

  const convertToCSV = (data) => {
    const headers = Object.keys(data[0]).join(",");
    const rows = data.map((row) => Object.values(row).join(",")).join("\n");
    return `${headers}\n${rows}`;
  };

  const downloadCSV = (data, filename) => {
    const csv = convertToCSV(data);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleDownloadRedCSV = () => {
    downloadCSV(redTableData, "red_flag_data.csv");
  };

  const handleDownloadOrangeCSV = () => {
    downloadCSV(orangeTableData, "orange_flag_data.csv");
  };

  const path = window.location.pathname

  return (
    <div className="col-12 mt-3">
      <h6 style={path && {fontSize: '14px', color: 'black'}}>Personally Identifiable Information</h6>
      {orangeFlag &&
      redFlag &&
      (filteredOrangeFlag?.length > 0  || filteredRedFlag?.length > 0 ) ? (
        <>
          <div className="row">
            <div className="col-12 tableBG">
              {filteredRedFlag.length > 0 && (
                <>
                  <div className="d-flex justify-content-between mt-3 mb-3 red_h6">
                    <h6 className="d-flex" style={path && {fontSize: '14px'}}>Red Flag Detection</h6>
                    <CloudDownloadOutlinedIcon
                      onClick={handleDownloadRedCSV}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <Table
                    wordWrap="break-word"
                    data={filteredRedFlag}
                    height={200}
                    width={300}
                    virtualized
                    rowHeight={5}
                    loading={filteredRedFlag.length === 0}
                    className="personal_tableRed"
                    style={{
                      scrollbarWidth: "0",
                      border: "1px solid #ffdcdc",
                    }}
                  >
                    {/* Dynamically creating table columns */}
                    {Object.keys(filteredRedFlag[0]).map((key, index) => (
                      <Column key={index} flexGrow={2} align="center">
                        <HeaderCell style={ path && {fontSize: '14px'}}>{key}</HeaderCell>
                        <Cell style={path && {fontSize: '9px'}} dataKey={key} />
                      </Column>
                    ))}
                  </Table>
                  <div
                    style={{ paddingTop: 20 }}
                    className="personal_table_pag_color"
                  >
                    <Pagination
                      prev
                      next
                      first
                      last
                      ellipsis
                      boundaryLinks
                      maxButtons={5}
                      size="xs"
                      layout={["total", "-", "limit", "|", "pager", "skip"]}
                      total={filteredRedFlag.length}
                      limitOptions={[10, 30, 50]}
                      limit={redPageSize}
                      activePage={redPage}
                      onSelect={handleRedPageChange}
                      onChangeLimit={handleRedLimitChange}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 tableBG mt-3">
              {filteredOrangeFlag.length > 0 && (
                <>
                  <div className="d-flex justify-content-between mt-3 mb-3 orange_h6">
                    <h6 className="d-flex">Orange Flag Detection</h6>
                    <CloudDownloadOutlinedIcon
                      onClick={handleDownloadOrangeCSV}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <Table
                    wordWrap="break-word"
                    data={filteredOrangeFlag}
                    height={200}
                    width={300}
                    virtualized
                    rowHeight={25}
                    loading={filteredOrangeFlag.length === 0}
                    className="personal_tableOrange"
                    style={{
                      scrollbarWidth: "0",
                      maxHeight: "50vh",
                      border: "1px solid #f6e9d6",
                    }}
                  >
                    {/* Dynamically creating table columns */}
                    {Object.keys(filteredOrangeFlag[0]).map(
                      (key, index) => (
                        <Column key={index} flexGrow={2} align="center">
                          <HeaderCell style={path && {fontSize: '14px'}}>{key}</HeaderCell>
                          <Cell style={ path && {fontSize: '9px'}} dataKey={key} />
                        </Column>
                      )
                    )}
                  </Table>
                  <div
                    style={{ paddingTop: 20 }}
                    className="personal_table_pag_color"
                  >
                    <Pagination
                      prev
                      next
                      first
                      last
                      ellipsis
                      boundaryLinks
                      maxButtons={5}
                      size="xs"
                      layout={["total", "-", "limit", "|", "pager", "skip"]}
                      total={filteredOrangeFlag.length}
                      limitOptions={[10, 30, 50]}
                      limit={orangePageSize}
                      activePage={orangePage}
                      onSelect={handleOrangePageChange}
                      onChangeLimit={handleOrangeLimitChange}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        path === '/mlrReview path' ? <div className="content-Loader">
        <CommonLoader/>
      </div> : <div  style={{color: "red"}}>
      No content for this page 
      </div>
      )}
    </div>
  );
};

export default PersonalIdentification;
